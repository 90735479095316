import React, { memo } from "react";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Box, Tab } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./styles";
import { notNil } from "../utils";

const Tabs = (props) => {
  const classes = useStyles();
  const {
    className,
    tabs = [],
    color = "primary",
    orientation = "horizontal",
    onSelect = null,
  } = props;
  const [currentTab, setCurrentTab] = React.useState("0");

  const handleChange = (event, value) => {
    setCurrentTab(value);
    if (notNil(onSelect)) {
      onSelect(value);
    }
  };

  return (
    <Box className={clsx(className, classes.tabsWrapper, orientation)}>
      <TabContext value={currentTab}>
        <TabList
          orientation={orientation}
          onChange={handleChange}
          indicatorColor={color}
          className={clsx(classes.tabList, orientation)}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={tab.key || tab.label}
              className={classes.tab}
              value={`${index}`}
              label={tab.label}
            />
          ))}
        </TabList>

        {tabs.map((tab, index) => (
          <TabPanel
            key={tab.key || tab.label}
            value={`${index}`}
            className={clsx(classes.tabPanel, orientation)}
          >
            {tab.content}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default memo(Tabs);
