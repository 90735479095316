import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tabsWrapper: {
    display: "flex",
    "&.horizontal": {
      flexDirection: "column",
    },
    "&.vertical": {
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
    },
  },

  tabList: {
    "&.horizontal": {
      marginBottom: theme.spacing(2),
    },
    "&.vertical": {
      borderRight: "1px solid",
      borderRightColor: theme.palette.divider,
    },
  },

  tab: {
    "&.Mui-selected": {
      backgroundColor: theme.palette.action.selected,
    },
  },

  tabPanel: {
    flex: 1,
    "&.horizontal": {
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
    },
  },
}));

export default useStyles;
