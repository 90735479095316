import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

const DispositionRole = ({ data }) => {
  return (
    <>
      <TableContainer className="tableWrapper">
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Disposition</TableCell>
              <TableCell align="center">Code</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(data).map((name) => (
              <TableRow key={name}>
                <TableCell>{name}</TableCell>
                <TableCell align="center">{data[name]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default DispositionRole;
