import * as R from "ramda";
import { convertDate } from "../../../lib/utils";

const emptyDefault = R.ifElse(R.isEmpty, R.always("-"), R.identity);

export const generatePhoneInfo = (phoneInfo, timeLocalizer) => {
  return [
    {
      field: "Active",
      value: R.prop("active", phoneInfo),
    },
    {
      field: "Area Code",
      value: R.prop("areaCode", phoneInfo),
    },
    {
      field: "Country Code",
      value: R.prop("countryCode", phoneInfo),
    },
    {
      field: "Date Created",
      value: R.prop("dateCreated", phoneInfo),
    },
    {
      field: "Date Created Timestamp",
      value: convertDate(R.prop("dateCreatedTimestamp", phoneInfo), timeLocalizer),
    },
    {
      field: "Friendly Name",
      value: R.prop("friendlyName", phoneInfo),
    },
    {
      field: "Last Synced Epoch",
      value: convertDate(R.prop("lastSyncedEpoch", phoneInfo), timeLocalizer),
    },
    {
      field: "Last Updated Epoch",
      value: convertDate(R.prop("lastUpdatedEpoch", phoneInfo), timeLocalizer),
    },
    {
      field: "Phone Number",
      value: R.prop("phoneNumber", phoneInfo),
    },
    {
      field: "SID",
      value: R.prop("sid", phoneInfo),
    },
    {
      field: "Status Callback",
      value: emptyDefault(R.prop("statusCallback", phoneInfo)),
    },
    {
      field: "Twilio Account Sid",
      value: R.prop("twilioAccountSid", phoneInfo),
    },
    {
      field: "Type",
      value: R.prop("type", phoneInfo),
    },
    {
      field: "Voice Url",
      value: R.prop("voiceUrl", phoneInfo),
    },
  ];
};
