import { Chip, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { last, pathOr, pipe, split } from "ramda";

const LOIS = ["med_advantage", "medicare", "auto_home", "life", "health"];

export const renderUserIdField = (filterValue, onChange, error = null) => (
  <Autocomplete
    multiple
    id="user-ids"
    options={[]}
    value={filterValue}
    freeSolo
    renderTags={(value, getTagProps) =>
      value.map((option, index) => (
        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
      ))
    }
    renderInput={(params) => (
      <TextField {...params} error={error} placeholder="User Ids" />
    )}
    onChange={(e, value) => onChange(value)}
  />
);

export const renderLineOfInsuranceField = (filterValue, onChange, error = null) => (
  <Autocomplete
    id="lois"
    options={LOIS}
    value={filterValue}
    onChange={(e, value) => onChange(value)}
    renderInput={(params) => (
      <TextField {...params} error={error} placeholder="Select LOI" />
    )}
  />
);

export const extractError = pipe(
  pathOr("Something went wrong", ["response", "data", "Message"]),
  split("BadRequestError: "),
  last,
);
