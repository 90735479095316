import React, { useState } from "react";
import * as R from "ramda";
import ExecutionPlan from "./ExecutionPlan";
import { Typography, Paper, Link } from "@material-ui/core";
import LeadInfo from "./LeadInfo";
import DisplayCallInfo from "../DisplayInfoBox";
import { generateCallRoutingCallInfo } from "../call_doc/generateInfo";
import * as moment from "moment-timezone";
import { createTimeLocalizer } from "../../../lib/utils";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import camelcaseKey from "camelcase-keys";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import useStyles from "./styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Skeleton from "@material-ui/lab/Skeleton";
import useAxios from "../../../hooks/useAxios";
import SearchBox from "../../../lib/SearchBox";
import { Alert, AlertTitle } from "@material-ui/lab";

const timeZone = moment.tz.guess(true);
const timeLocalizer = createTimeLocalizer(timeZone);

const CallRoutingDocument = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState("Call");
  const params = useParams();
  const history = useHistory();
  const axios = useAxios();
  const { callRoutingId } = params;
  const query = useQuery(
    ["callRoutingId", callRoutingId],
    () => axios.post("get_call_routing_document", { routing_id: callRoutingId }),
    { enabled: !!callRoutingId },
  );
  const callRoutingDoc = camelcaseKey(R.path(["data", "data"], query), { deep: true });
  const transferTaskSid = callRoutingDoc?.transferTaskSid ?? null;

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const loadingSkeleton = (
    <Box className={classes.skeleton}>
      <Skeleton variant="rect" height={25} />
      <Skeleton variant="rect" height={48} />
      <Skeleton variant="rect" height={418} />
    </Box>
  );

  const tabs = !!callRoutingDoc
    ? {
        Call: (
          <DisplayCallInfo
            data={generateCallRoutingCallInfo(callRoutingDoc, timeLocalizer)}
            timeLocalizer={timeLocalizer}
          />
        ),

        Lead: <LeadInfo lead={R.prop("lead", callRoutingDoc)} />,

        "Execution Plan": (
          <ExecutionPlan executionPlan={R.prop("executionPlan", callRoutingDoc)} />
        ),
      }
    : null;

  return (
    <Container maxWidth="md" className={classes.container}>
      <SearchBox
        label="Call Routing ID"
        value={callRoutingId}
        onSearch={(value) => history.push(`/call-routing-document/${value}`)}
      />

      {query.isLoading && loadingSkeleton}

      {transferTaskSid && (
        <Box className={classes.transferTaskSid}>
          <Typography variant="subtitle2">Transfer Task:</Typography>

          <Link href={`/call-document/${transferTaskSid}`}>
            <Chip label={transferTaskSid} size="small" />
          </Link>
        </Box>
      )}

      {query.error && (
        <Alert severity="error" className={classes.error}>
          <AlertTitle>Couldn't find the call routing document</AlertTitle>
          Couldn't find a call routing document with ID {callRoutingId}
        </Alert>
      )}

      {query.status === "success" && (
        <>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            textColor="primary"
            value={currentTab}
            variant="scrollable"
            className={classes.tabs}
          >
            {Object.keys(tabs).map((tab) => (
              <Tab className={classes.tab} key={tab} label={tab} value={tab} />
            ))}
          </Tabs>
          <Paper className={classes.tabContent}>{tabs[currentTab]}</Paper>
        </>
      )}
    </Container>
  );
};
export default CallRoutingDocument;
