import { stripPhoneNumber } from "./PhoneNumberMask";
import * as moment from "moment-timezone";
import { formatPhoneNumber as _formatPhoneNumber } from "react-phone-number-input";
import {
  and,
  assoc,
  complement,
  compose,
  concat,
  converge,
  dissoc,
  either,
  equals,
  forEach,
  head,
  identity,
  ifElse,
  includes,
  intersection,
  invoker,
  is,
  isEmpty,
  isNil,
  join,
  map,
  mapObjIndexed,
  not,
  pipe,
  prop,
  replace,
  sortBy,
  split,
  startsWith,
  tail,
  toUpper,
  values,
} from "ramda";

export const isEmptyOrNil = either(isNil, isEmpty);

export const isNotEmpty = and(complement(isNil), complement(isEmpty));

export const notNil = complement(isNil);

export const isObject = is(Object);

export const isBoolean = is(Boolean);

export const isString = is(String);

export const isUrl = (val) => !isObject(val) && startsWith("https", val);

export const convertToSentenceCase = (key) => {
  // adding space between strings
  const result = key.replace(/([A-Z])/g, " $1");
  // converting first character to uppercase and join it to the final string
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const isCustomUrl = (val) => prop("customUrl", val) && prop("customUrlLabel", val);

const convertToFieldValue = (val, key) => {
  const customUrl = isCustomUrl(val) ? prop("customUrl", val) : null;
  const customUrlLabel = isCustomUrl(val) ? prop("customUrlLabel", val) : null;

  if (isEmptyOrNil(val)) {
    return {
      field: convertToSentenceCase(key),
      value: "-",
    };
  }
  return {
    field: convertToSentenceCase(key),
    value: customUrlLabel || val,
    type: customUrl || isUrl(val) ? "link" : null,
    link: customUrl ? customUrl : isUrl(val) ? val : null,
  };
};

export const createWidgetAttributes = pipe(mapObjIndexed(convertToFieldValue), values());

export const createAlphabeticalWidgetAttributes = pipe(
  createWidgetAttributes,
  sortBy(prop("field")),
);

const addLeadDialIdPayload = (leadDialIdPayload) =>
  assoc("leadDialId", leadDialIdPayload);

const removeLeadEvents = dissoc("leadEvents");

export const callRoutingDocumentUrl = (val) => `/call-routing-document/${val}`;

export const callDocumentUrl = (val) => `/call-document/${val}`;

export const phoneNumberUrl = (val) => `/phone-info/${val}`;

export const formatPhoneNumber = (number, showCountryCode = true) => {
  return `${showCountryCode ? "+1 " : ""}${_formatPhoneNumber(
    formattedPhoneNumber(`${number}`),
  )}`;
};
export const formattedPhoneNumber = (number) => `+1${stripPhoneNumber(number)}`;

export const formattedPhoneNumberNoCode = (number) => `${stripPhoneNumber(number)}`;

const createUrlPayload = (label, url) => {
  return {
    customUrlLabel: label,
    customUrl: url,
  };
};

const createLeadDialIdPayload = ({ isMaestroRedirect, lead }) => {
  const leadDialId = prop("leadDialId", lead);
  if (!leadDialId) {
    return null;
  }
  if (isMaestroRedirect) {
    return createUrlPayload(leadDialId, "https://maestro.assurance.com/#/call_logs");
  }
  return createUrlPayload(leadDialId, callRoutingDocumentUrl(leadDialId));
};

export const getTransformedLeadInfo = ({ isMaestroRedirect, lead }) => {
  const leadDialIdPayload = createLeadDialIdPayload({
    isMaestroRedirect,
    lead,
  });
  return leadDialIdPayload
    ? pipe(addLeadDialIdPayload(leadDialIdPayload), removeLeadEvents)(lead)
    : removeLeadEvents(lead);
};

export const isSuccesfullApiCall = pipe(prop("status"), equals(200));

export const mapObjIndexedAndReturnValues = pipe(mapObjIndexed, values);

export const convertDate = (timeStamp, timeLocalizer) => {
  return pipe(ifElse(isNil, identity, pipe(timeLocalizer, invoker(0, "toString"))))(
    timeStamp,
  );
};

export const transformRole = (role) => {
  return pipe(addAgentLabel, replace(/_/g, " "), toTitleCase)(role);
};

// Append a space before capital letters unless the previous character is already a space
// or a capital letter
export const transformDisposition = (disposition) => {
  if (!disposition) {
    return "";
  }

  let transformed = disposition[0];
  let prevChar = disposition[0];

  for (let i = 1; i < disposition.length; i++) {
    // Check if the current character is uppercase and not the first character
    if (disposition[i] === disposition[i].toUpperCase() && disposition[i] !== " ") {
      // Check if the preceding character is not a space or uppercase
      if (prevChar !== " " && prevChar === prevChar.toLowerCase()) {
        transformed += " "; // Insert a space before the uppercase letter
      }
    }

    transformed += disposition[i];
    prevChar = disposition[i];
  }

  return transformed;
};

const agentRoles = [
  "medicare",
  "auto_home",
  "life",
  "health",
  "mortgage",
  "med_advantage",
  "accidental_death",
];

const addAgentLabel = (role) => {
  if (includes(role, agentRoles)) {
    return `${role}_agent`;
  }
  return role;
};

const capitalize = converge(concat, [compose(toUpper, head), tail]);

const toTitleCase = compose(join(" "), map(capitalize), split(" "));

export const sortObjByKeys = (obj) => {
  let sortedObject = {};
  forEach(
    (key) => {
      sortedObject[key] = obj[key];
    },
    sortBy(prop(0), Object.keys(obj)),
  );
  return sortedObject;
};

export function guuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

export const camelCase = (str) => str.replace(/[-_]([a-z])/g, (m) => m[1].toUpperCase());

export const createTimeLocalizer = (timeZone) => (epoch) =>
  moment.unix(epoch).tz(timeZone);

export const createTimeFormatter = (timeLocalizer) => (time) =>
  timeLocalizer(time).format("HH:mm:ss");

export const getInitials = (name = "") =>
  name
    .replace(/\s+/, " ")
    .split(" ")
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join("");

export const notEmptyIntersection = pipe(intersection, isEmpty, not);
