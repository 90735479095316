import auth0Client from "./Auth";
import React, { useEffect } from "react";
import Loading from "../effects/Loading";
import { AUTH_CONFIG } from "./auth0-variables";

export default function SignOut() {
  useEffect(() => {
    auth0Client.signOut();
    window.location.replace(
      `https://${AUTH_CONFIG.domain}/logout?client_id=${AUTH_CONFIG.clientId}&returnTo=${process.env.REACT_APP_URL}`,
    );
  });
  return <Loading />;
}
