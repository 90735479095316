import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  Add,
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
  Cancel,
  Delete,
  ExpandMore,
  Remove,
  Save,
  Shuffle,
} from "@material-ui/icons";
import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import { and, equals, isEmpty, not } from "ramda";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loading from "../../../effects/Loading";
import useAxios from "../../../hooks/useAxios";
import NewFilterModal from "./NewFilterModal";
import useStyles from "./styles";
import { extractError } from "./utils";
import VersionFilters from "./VersionFilters";

const DialerVersions = (props) => {
  const classes = useStyles();
  const axios = useAxios();
  const [avaialbleVersions, setAvailableVersions] = useState([]);
  const [activeVersions, setActiveVersions] = useState([]);
  const [speedDialOpen, setDial] = useState(false);
  const [remove, setRemove] = useState(false);
  const [add, setAdd] = useState(false);
  const [shuffle, setShuffle] = useState(false);

  const updateVersions = () => {
    axios.post("get_dialer_versions").then((response) => {
      setAvailableVersions(response.data.versions);
    });
  };

  const updateFilters = () => {
    axios.post("get_dialer_filters").then((response) => {
      setActiveVersions(response.data.versions);
    });
  };

  const saveVersions = (newVersions) => {
    axios
      .post("update_dialer_filters", newVersions)
      .then(() => updateFilters())
      .catch((err) => {
        toast(extractError(err), { type: "error" });
        updateFilters();
      });
  };

  useEffect(() => {
    updateVersions();
    updateFilters();
  }, []);

  const onSave = (index, filters, comment, notify) => {
    let versions = activeVersions;
    versions[index].filters = filters;
    versions[index].comment = comment;
    versions[index].notify = notify;
    setActiveVersions(versions);
    saveVersions(versions);
  };

  const removeFilter = (e, index) => {
    e.stopPropagation();
    let versions = structuredClone(activeVersions);
    versions.splice(index, 1);
    setActiveVersions(versions);
  };

  const isDefaultVersion = (index) => equals(index, activeVersions.length - 1);

  const moveUp = (e, index) => {
    e.stopPropagation();
    let versions = structuredClone(activeVersions);
    let itemAtIndex = versions[index];
    versions[index] = versions[index - 1];
    versions[index - 1] = itemAtIndex;
    setActiveVersions(versions);
  };

  const moveDown = (e, index) => {
    e.stopPropagation();
    let versions = structuredClone(activeVersions);
    let itemAtIndex = versions[index];
    versions[index] = versions[index + 1];
    versions[index + 1] = itemAtIndex;
    setActiveVersions(versions);
  };

  const save = () => {
    setShuffle(false);
    setRemove(false);
    saveVersions(activeVersions);
  };

  const reset = () => {
    setShuffle(false);
    setRemove(false);
    updateFilters();
  };

  const renderAccordion = () =>
    activeVersions.map((activeVersion, index) => (
      <div key={`${activeVersion.version}-${index}`}>
        <Accordion defaultExpanded={equals(index, 0)} key={`${index}`}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            style={{ verticalAlign: "text-bottom" }}
          >
            <Grid container alignContent="space-between" alignItems="center">
              {and(!isDefaultVersion(index), shuffle) && (
                <Grid item>
                  {not(equals(0, index)) && (
                    <IconButton
                      onClick={(e) => moveUp(e, index)}
                      style={{ paddingRight: "15px" }}
                    >
                      <ArrowDropUpOutlined fontSize="small" />
                    </IconButton>
                  )}
                  {not(equals(activeVersions.length - 2, index)) && (
                    <IconButton
                      onClick={(e) => moveDown(e, index)}
                      style={{ verticalAlign: "middle" }}
                    >
                      <ArrowDropDownOutlined fontSize="small" />
                    </IconButton>
                  )}
                </Grid>
              )}
              {and(not(isDefaultVersion(index)), remove) && (
                <Grid item>
                  <IconButton onClick={(e) => removeFilter(e, index)}>
                    <Remove color="error" />
                  </IconButton>
                </Grid>
              )}
              <Grid item>
                <Typography style={{ height: "100%" }}>
                  {activeVersion.version} - {activeVersion.comment}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails key={`${index}`}>
            <VersionFilters
              version={activeVersion}
              index={index}
              onSave={onSave}
              isDefaultVersion={isDefaultVersion(index)}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    ));

  const renderControls = () => (
    <>
      <IconButton onClick={reset}>
        <Cancel />
      </IconButton>
      <IconButton onClick={() => save(false)}>
        <Save />
      </IconButton>
    </>
  );

  if (isEmpty(activeVersions)) {
    return <Loading />;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      {renderAccordion()}
      {(shuffle || remove) && renderControls()}

      <SpeedDial
        ariaLabel="Add or remove filter"
        aria-label="edit"
        variant="extended"
        color="primary"
        icon={<Add />}
        onOpen={() => setDial(true)}
        onClose={() => setDial(false)}
        open={speedDialOpen}
        style={{ alignItems: "end" }}
      >
        <SpeedDialAction
          icon={<Add />}
          tooltipTitle="Add"
          tooltipOpen
          onClick={() => setAdd(true)}
        />
        <SpeedDialAction
          icon={<Delete />}
          tooltipTitle="Delete"
          tooltipOpen
          onClick={() => setRemove(true)}
        />
        <SpeedDialAction
          icon={<Shuffle />}
          tooltipTitle="Reorder"
          tooltipOpen
          onClick={() => setShuffle(true)}
        />
      </SpeedDial>
      <Dialog open={add}>
        <NewFilterModal
          setAdd={setAdd}
          avaialbleVersions={avaialbleVersions}
          activeVersions={activeVersions}
          saveVersions={saveVersions}
        />
      </Dialog>
    </Container>
  );
};

export default DialerVersions;
