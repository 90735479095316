import { notEmptyIntersection } from "../lib/utils";
import auth0Client from "./Auth";

class RouteAuthorizer {
  canViewXfers = () =>
    notEmptyIntersection(
      [
        "delta_admin",
        "telephony_operator",
        "developer",
        "data_scientist",
        "product_manager",
        "sales_manager",
      ],
      auth0Client.roles,
    );

  canViewAutoExperiments = () =>
    notEmptyIntersection(
      [
        "delta_admin",
        "telephony_operator",
        "developer",
        "data_scientist",
        "product_manager",
        "sales_manager",
      ],
      auth0Client.roles,
    );

  canChangeDialingConfig = () =>
    notEmptyIntersection(["delta_admin", "telephony_operator"], auth0Client.roles);

  canChangeDeltaConfig = () => notEmptyIntersection(["delta_admin"], auth0Client.roles);

  canBlackListNumbers = () =>
    notEmptyIntersection(
      ["delta_admin", "telephony_operator", "tier3_support", "tier1_support"],
      auth0Client.roles,
    );

  canUpdateDispositions = () => notEmptyIntersection(["delta_admin"], auth0Client.roles);

  canUpdateDynamoFilters = () => notEmptyIntersection(["delta_admin"], auth0Client.roles);
}
const routeAuthorizer = new RouteAuthorizer();
export default routeAuthorizer;
