import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
  },

  skeleton: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },

  transferTaskSid: {
    display: "flex",
    marginTop: theme.spacing(2),
    gap: theme.spacing(1),
  },

  tabs: {
    marginTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  tab: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.71,
    minWidth: "auto",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textTransform: "none",
    "& + &": {
      marginLeft: 24,
    },
  },

  tabContent: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },

  tabTitle: {
    marginBottom: theme.spacing(2),
  },

  error: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
