import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ErrorDialog from "./../../../lib/ErrorDialog";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import SearchButton from "./../../../lib/SearchButton";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    border: "grey 2px solid",
    borderRadius: 5,
    boxShadow: 2,
    minHeight: 40,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRight: "solid grey 2px",
  },
  width: {},
}));
const SearchBox = (props) => {
  const { history } = props;
  const [error, setError] = React.useState();

  const classes = useStyles();

  const handleChange = (e) => {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    history.push(`/outbound-auto-experiments/${e.target[0].value}`);
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <ErrorDialog error={error} setError={setError} />}
      <Grid
        container
        className={classes.container}
        direction="row"
        alignItems="center"
        spacing={0}
      >
        <FormControl className={classes.textField}>
          <Input disableUnderline onChange={handleChange} inputComponent={TextField} />
        </FormControl>
        <SearchButton />
      </Grid>
    </form>
  );
};

const TextField = ({ inputRef, ...other }) => {
  return (
    <input
      size={34}
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
};

export default withRouter(SearchBox);
