import * as R from "ramda";
import * as moment from "moment";

const formatTimeDeltaSeconds = (seconds) => {
  const duration = moment.duration(seconds, "seconds");
  return {
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };
};
const displayTime = (time) => {
  const display = [];
  if (time.hours) {
    let hours = time.hours + " hour";
    if (time.hours > 1) {
      hours += "s";
    }
    display.push(hours);
  }
  if (time.minutes) {
    let minutes = time.minutes + " minute";
    if (time.minutes > 1) {
      minutes += "s";
    }
    display.push(minutes);
  }
  if (time.seconds) {
    let seconds = time.seconds + " second";
    if (time.minutes > 1) {
      seconds += "s";
    }
    display.push(seconds);
  }

  return display.join(", ");
};

const propsGet = R.flip(R.prop);

const getIntroRecording = R.path(["callInfo", "introRecording"]);

const isDefaultIntroRecording = R.pipe(
  getIntroRecording,
  R.startsWith("default_modified_intro"),
);

export const generateCallInfo = (callDoc, timeLocalizer) => {
  const conference = propsGet(callDoc.conference);
  const callInfo = propsGet(callDoc.callInfo);
  const convertDate = R.pipe(
    conference,
    R.ifElse(R.isNil, R.identity, R.pipe(timeLocalizer, R.invoker(0, "toString"))),
  );

  const data = [
    {
      field: "Start Time",
      value: convertDate("startTimeEpoch"),
    },
    {
      field: "End Time",
      value: convertDate("endTimeEpoch"),
    },
    {
      field: "Call Duration",
      value: R.pipe(
        R.subtract,
        R.ifElse(isNaN, R.always(null), R.pipe(formatTimeDeltaSeconds, displayTime)),
      )(conference("endTimeEpoch"), conference("startTimeEpoch")),
    },
    {
      field: "Call Recording",
      type: "audio",
      link: callInfo("conferenceRecording"),
    },
    {
      field: "Dial Direction",
      value: callInfo("dialDirection"),
    },
    {
      field: "Disposition",
      value: callInfo("disposition"),
    },
    {
      field: "Session Recording",
      type: "newWindowLink",
      value: R.isNil(callInfo("logRocketSessionUrl")) ? "-" : "Logrocket",
      link: callInfo("logRocketSessionUrl"),
    },
  ];

  if (R.not(R.isNil(getIntroRecording(callDoc)))) {
    const recordingType = isDefaultIntroRecording() ? "default" : "personalized";
    data.push({
      field: "Intro Recording",
      value: `${getIntroRecording(callDoc)} (${recordingType})`,
    });
  }
  return data;
};

export const generateCallRoutingCallInfo = (doc, timeLocalizer) => {
  const conference = R.prop("conference", doc);
  const convertDate = R.pipe(timeLocalizer, R.invoker(0, "toString"));

  const data = [];
  if (conference.startTimeEpoch) {
    data.push({
      field: "Conference Start Time",
      value: convertDate(conference.startTimeEpoch),
    });

    if (conference.endTimeEpoch) {
      data.push({
        field: "Conference End Time",
        value: convertDate(conference.endTimeEpoch),
      });
      data.push({
        field: "Conference Duration",
        value: R.pipe(
          R.subtract,
          R.ifElse(isNaN, R.always(null), R.pipe(formatTimeDeltaSeconds, displayTime)),
        )(conference.endTimeEpoch, conference.startTimeEpoch),
      });
    } else if (conference.startTimeEpoch) {
      data.push({
        field: "Message",
        value: "The conference has not yet ended",
      });
    }
  } else {
    data.push({
      field: "Message",
      value: "Conference not found",
    });
  }

  if (R.prop("conferenceRecording", doc)) {
    data.push({
      field: "Conference Recording",
      type: "audio",
      link: R.prop("conferenceRecording", doc),
    });
  }

  if (R.prop("leadRecording", doc)) {
    data.push({
      field: "Lead Call Recording",
      type: "audio",
      link: R.prop("leadRecording", doc),
    });
  }

  return data;
};

export const generateLeadInfo = (callDoc, timeLocalizer) => {
  const leadInfo = propsGet(callDoc.leadInfo);
  return [
    {
      field: "Name",
      value: leadInfo("name"),
    },
    {
      field: "City",
      value: leadInfo("city"),
    },
    {
      field: "State",
      value: leadInfo("state"),
    },
    {
      field: "Assurance Customer Id",
      value: leadInfo("id"),
    },
    {
      field: "Campaign Name",
      value: leadInfo("utmSource"),
    },
    {
      field: "UTM Source",
      value: leadInfo("state"),
    },
  ];
};

export const generateAgentInfo = (callDoc, timeLocalizer) => {
  const agentInfo = propsGet(callDoc.agentInfo);
  const getAndJoin = R.pipe(agentInfo, R.ifElse(R.isNil, R.identity, R.join(", ")));
  return [
    {
      field: "Name",
      value: agentInfo("fullName"),
    },
    {
      field: "Email",
      value: agentInfo("email"),
    },
    {
      field: "Phone Number",
      value: agentInfo("phoneNumber"),
    },
    {
      field: "Start Date",
      value: agentInfo("startDate"),
    },
    {
      field: "Assurance Agent Id",
      value: agentInfo("assuranceUserId"),
    },
    {
      field: "Join Agent Id",
      value: agentInfo("joinAgentId"),
    },
    {
      field: "Delta User Id",
      value: agentInfo("assuranceUserId"),
    },
    {
      field: "Tier",
      value: agentInfo("tier"),
    },
    {
      field: "Team Name(s)",
      value: getAndJoin("teamName"),
    },
    {
      field: "License States",
      value: getAndJoin("licenseStates"),
    },
  ];
};

export const generateDevInfo = (callDoc) => {
  const conference = propsGet(callDoc.conference);
  const devInfo = propsGet(callDoc.devInfo);

  return [
    {
      field: "Agent Hung Up",
      value: conference("agentHungup"),
    },
    {
      field: "Conference Sid",
      value: conference("sid"),
    },
    {
      field: "Participants",
      value: conference("participantsInfo"),
    },
    {
      field: "Twilio Task Assignment Status",
      value: devInfo("twilioTaskAssignmentStatus"),
    },
  ];
};
