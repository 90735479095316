import App from "./App";
import { Route, Switch } from "react-router-dom";
import React from "react";
import SignOut from "./auth/SignOut";
import Callback from "./auth/Callback";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/callback" component={Callback} />
      <Route exact path="/signout" component={SignOut} />
      <Route path="/" component={App} />
    </Switch>
  );
}
