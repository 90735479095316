import React, { memo } from "react";
import auth0Client from "../../../auth/Auth";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useMutation, useQuery } from "react-query";
import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import Tabs from "../../../lib/Tabs";
import useAxios from "../../../hooks/useAxios";
import DynamicControlForm from "../DynamicControlForm";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

const DeltaConfiguration = () => {
  const classes = useStyles();
  const axios = useAxios();

  const query = useQuery(["dial-service-config"], () =>
    axios.post("get_dialing_service_config"),
  );

  const updateConfigMutation = useMutation(
    (params) =>
      axios.post("update_dialing_service_config", {
        config_update: params,
        user_id: auth0Client.getUserId(),
      }),
    { onSuccess: query.refetch },
  );

  const deltaConfig = query?.data?.data;

  return (
    <Container className={classes.root} maxWidth="md">
      <Tabs
        orientation="vertical"
        tabs={[
          {
            label: "Dial throttling",
            content: (
              <DynamicControlForm
                config={deltaConfig || {}}
                handleSubmit={updateConfigMutation.mutate}
                isSubmitting={updateConfigMutation.isLoading}
                config_key={"outbound_dial_throttling"}
              />
            ),
          },
          {
            label: "Outbound Express",
            content: (
                <DynamicControlForm
                    config={deltaConfig || {}}
                    handleSubmit={updateConfigMutation.mutate}
                    isSubmitting={updateConfigMutation.isLoading}
                    config_key={"outbound_express_dev"}
                />
            ),
          },
        ]}
      />

      <Snackbar
        open={updateConfigMutation.isSuccess}
        autoHideDuration={5000}
        onClose={updateConfigMutation.reset}
        message="Config updated"
      >
        <Alert severity="success">Config has been updated</Alert>
      </Snackbar>

      <Snackbar
        open={updateConfigMutation.isError}
        autoHideDuration={5000}
        onClose={updateConfigMutation.reset}
      >
        <Alert severity="error" className={classes.error}>
          <AlertTitle>{updateConfigMutation.error?.code}</AlertTitle>
          {updateConfigMutation.error?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default memo(DeltaConfiguration);
