import React from "react";
import * as R from "ramda";
import { Typography, Box } from "@material-ui/core";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { callDocumentUrl } from "../../../lib/utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    border: "solid grey 2px",
    boxShadow: "5px 6px  #888888",
    width: 600,
  },
  task: {
    display: "block",
  },
}));

const ActiveTasks = ({ activeTasks }) => {
  const classes = useStyles();
  if (R.isEmpty(activeTasks)) {
    return null;
  }

  return (
    <Grid container>
      <Grid item>
        <Typography align="center" variant="h5">
          Tasks
        </Typography>
        <Paper className={classes.paper}>
          <Grid
            container
            alignContent="stretch"
            direction="row"
            wrap="nowrap"
            spacing={1}
          >
            <Grid item>
              <Typography component="div" variant="subtitle1">
                <Box fontWeight="fontWeightBold">
                  {R.length(activeTasks) === 1 ? "Active Task:" : "Active Tasks:"}
                </Box>
              </Typography>
            </Grid>
            <Grid item>
              {activeTasks.map((activeTask) => (
                <a className={classes.task} href={callDocumentUrl(activeTask)}>
                  {activeTask}
                </a>
              ))}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default ActiveTasks;
