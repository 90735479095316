import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Typography, Box, TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import useStyles from "./styles";

const validationSchema = yup.object({
  dialing_pools_config: yup.object({
    dial_ratio: yup
      .number("Number")
      .min(0, "Dial ratio must be greater than or equal to 0")
      .max(10, "Dial ratio must be smaller than or equal to 10"),
  }),
});

const DialingPools = (props) => {
  const { value, handleSubmit, isSubmitting } = props;
  const classes = useStyles();

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      dialing_pools_config: {
        dial_ratio: value,
      },
    },
    mode: "onChange",
  });

  return (
    <Box className={classes.datatable}>
      <Typography variant="subtitle2">
        The dial ratio controls the number of concurrent calls made in the power dialer.
        It must be a number between 0.0 and 10.0.
      </Typography>

      <Box>
        <Controller
          control={form.control}
          name="dialing_pools_config.dial_ratio"
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="number"
              size="small"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              className={classes.numberInput}
            />
          )}
        />
      </Box>

      <Box>
        <Button
          onClick={form.handleSubmit(handleSubmit)}
          disabled={!form.formState.isValid || isSubmitting}
          variant="contained"
          color="primary"
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default DialingPools;
