import React, { memo, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import {
  Select,
  MenuItem,
  Typography,
  Paper,
  TextField,
  Button,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import * as R from "ramda";
import { Alert } from "@material-ui/lab";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import FormControl from "@material-ui/core/FormControl";
import { US_STATES } from "../../../constants";
import { useMutation } from "react-query";
import useAxios from "../../../hooks/useAxios";
import auth0Client from "../../../auth/Auth";

const ALLOWED_LOIS = [
  "inbound_guide_rep",
  "auto_home",
  "med_advantage",
  "life",
  "health",
  "medicare",
];

const styles = {
  loi: {
    p: 2,
    mb: 3,
    borderRadius: 0,
  },

  loiTitle: {
    textTransform: "uppercase",
    textAlign: "center",
    width: "100%",
  },

  loiCategory: {
    marginY: 3,
  },

  empty: {
    pt: 2,
  },

  bottomBox: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    gap: 16,
  },

  loiCategoryTextInput: {},

  categoryRow: {
    height: 60,
    backgroundColor: "#ededed",
    marginBottom: 4,
  },

  addCategoryRow: {
    marginTop: 24,
    display: "block",
    marginLeft: "auto",
  },

  tableRow: {
    display: "flex",
    gap: 16,
    height: 60,
    borderBottom: "1px solid",
    borderBottomColor: "#e7e7e7",
    mb: 1,
  },

  tableCellRight: {
    flex: 1,
  },

  textField: {
    borderBottom: "unset",
  },

  addStateBox: {
    marginY: 3,
    display: "flex",
    justifyContent: "flex-end",
    gap: 16,
    alignItems: "center",
  },
};

const AddKeyValuePairDialog = ({ onAdd, onCancel, loi, category, index }) => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");

  return (
    <Dialog open>
      <DialogTitle>Add Configuration Value</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Key"
            value={key}
            size="small"
            onChange={(e) => setKey(e.target.value)}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Value"
            value={value}
            size="small"
            onChange={(e) => setValue(e.target.value)}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => onAdd({ loi, category, index, key, value })}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddStateSelect = ({ loi, onAdd, exitingStates = [] }) => {
  const [value, setValue] = useState("");

  return (
    <Box sx={styles.addStateBox}>
      <FormControl style={{ marginLeft: "auto", width: 200 }}>
        <InputLabel>Add State</InputLabel>
        <Select value={value} onChange={(e) => setValue(e.target.value)}>
          {Object.keys(US_STATES)
            .filter((s) => !exitingStates.includes(s))
            .map((stateKey) => (
              <MenuItem key={stateKey} value={stateKey}>
                {US_STATES[stateKey]} ({stateKey})
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>Select a state to add configuration</FormHelperText>
      </FormControl>

      <Box>
        <Button disabled={!value} onClick={() => onAdd(loi, value)}>
          Add
        </Button>
      </Box>
    </Box>
  );
};

const AddConfigurationRow = ({ onAdd, loi, category }) => {
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState({
    agentUtilization: null,
    callLtv: null,
  });

  const toggleExpanded = () => setExpanded((prev) => !prev);

  const handleChange = (key) => (e) => {
    setValue((prev) => ({
      ...prev,
      [key]: Number(e.target.value),
    }));
  };

  const handleAdd = () => {
    toggleExpanded();
    onAdd(value, loi, category);
    setValue({
      agentUtilization: null,
      callLtv: null,
    });
  };

  return expanded ? (
    <Box sx={{ border: "1px solid #fff", p: 2 }}>
      <Box sx={{ display: "flex", gap: 16 }}>
        <TextField
          label="agent_utilization"
          onChange={handleChange("agentUtilization")}
          type="number"
          value={value.agentUtilization}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          label="call_ltv"
          onChange={handleChange("callLtv")}
          type="number"
          value={value.callLtv}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>

      <Box sx={{ display: "flex", gap: 16, justifyContent: "flex-end", mt: 2 }}>
        <Button onClick={toggleExpanded} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleAdd}
          disabled={value.agentUtilization === null || value.callLtv === null}
        >
          Add
        </Button>
      </Box>
    </Box>
  ) : (
    <Button style={styles.addCategoryRow} onClick={toggleExpanded}>
      Add Configuration Row
    </Button>
  );
};

const CallRedirectingPolicy = ({ value, refetchConfig }) => {
  const [editing, setEditing] = useState({});
  const [formData, setFormData] = useState(value);
  const [keyValuePair, setKeyValuePair] = useState(null);
  const [scrollEl, setScrollEl] = useState(null);
  const axios = useAxios();

  const toggleEdit = (loi) => {
    setEditing((prev) => ({
      ...prev,
      [loi]: !editing[loi],
    }));
  };

  const handleChange = (path, value) => {
    setFormData((prev) => R.assocPath(path, Number(value))(prev));
  };

  const handleCancel = (loi) => {
    toggleEdit(loi);
    setFormData(value);
  };

  const handleAddLoiConf = (loi) => {
    setFormData((prev) => ({
      ...prev,
      [loi]: {
        default: [],
      },
    }));

    toggleEdit(loi);
    setScrollEl(loi);
  };

  const handleAddCategoryRow = (value, loi, category) => {
    setFormData((prev) => ({
      ...prev,
      [loi]: {
        ...formData[loi],
        [category]: [
          ...formData[loi][category],
          {
            agent_utilization: value.agentUtilization,
            call_ltv: value.callLtv,
          },
        ].sort((a, b) => (a?.agent_utilization > b?.agent_utilization ? 1 : -1)),
      },
    }));
  };

  const handleAddState = (loi, state) => {
    setFormData((prev) => ({
      ...prev,
      [loi]: {
        ...prev[loi],
        [state]: [],
      },
    }));
  };

  const handleRemoveRow = (loi, category, index) => {
    setFormData((prev) => {
      const list = prev?.[loi]?.[category];
      const newList = list.filter((_, i) => index !== i);

      prev[loi][category] = newList;
      return {
        ...prev,
      };
    });
  };

  const handleAddKeyValuePair = ({ loi, category, index, key, value }) => {
    const listItem = formData?.[loi]?.[category]?.[index];
    listItem[key] = value;

    setFormData((prev) => ({
      ...prev,
      [loi]: {
        ...prev[loi],
        [category]: prev[loi][category],
      },
    }));
    setKeyValuePair(null);
  };

  useEffect(() => {
    if (scrollEl) {
      document
        .getElementById(scrollEl)
        .scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [scrollEl]);

  const saveMutation = useMutation(
    (params) =>
      axios.post("update_call_redirecting_policy", {
        config_update: formData,
        user_id: auth0Client.getUserId(),
      }),
    {
      onSuccess: () => {
        refetchConfig();
        setEditing({});
      },
    },
  );

  const handleSave = () => {
    saveMutation.mutate();
  };

  return (
    <Box>
      {ALLOWED_LOIS.filter((loi) => !!formData[loi])
        .sort()
        .map((loi) => (
          <Box id={loi} sx={styles.loi} key={loi} component={Paper} elevation={1}>
            <Typography variant="subtitle1" style={styles.loiTitle} gutterBottom>
              {loi}
            </Typography>

            {Object.keys(formData[loi]).map((category) => (
              <Box sx={styles.loiCategory} key={category}>
                <TextField
                  label="State"
                  value={category}
                  disabled
                  style={styles.loiCategoryTextInput}
                  variant="filled"
                />

                <Box sx={{ backgroundColor: "#e7e7e7", p: 2 }}>
                  {formData[loi][category].map((row, index) => (
                    <Box
                      sx={styles.tableRow}
                      key={`${loi}-${category}-${index}`}
                      align="right"
                    >
                      {Object.keys(row).map((rowKey) => (
                        <Box key={rowKey}>
                          <TextField
                            size="small"
                            label={rowKey}
                            value={row[rowKey]}
                            disabled={!editing[loi]}
                            onChange={(e) =>
                              handleChange([loi, category, index, rowKey], e.target.value)
                            }
                            InputProps={{
                              disableUnderline: !editing[loi],
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="number"
                          />
                        </Box>
                      ))}
                      {editing[loi] && (
                        <Box sx={styles.tableCellRight}>
                          <IconButton
                            size="small"
                            color="secondary"
                            onClick={() => handleRemoveRow(loi, category, index)}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  ))}

                  {editing[loi] && (
                    <AddConfigurationRow
                      onAdd={handleAddCategoryRow}
                      loi={loi}
                      category={category}
                    />
                  )}
                </Box>
              </Box>
            ))}

            {editing[loi] && (
              <AddStateSelect
                loi={loi}
                onAdd={handleAddState}
                exitingStates={Object.keys(formData[loi])}
              />
            )}

            <Box style={styles.bottomBox}>
              {!editing[loi] && (
                <Button
                  onClick={() => toggleEdit(loi)}
                  endIcon={<EditIcon fontSize="small" />}
                  variant="outlined"
                >
                  Edit
                </Button>
              )}

              {editing[loi] && (
                <>
                  <Button
                    onClick={() => handleCancel(loi)}
                    endIcon={<CloseIcon fontSize="small" />}
                    variant="outlined"
                    color="secondary"
                  >
                    Cancel
                  </Button>

                  <Button
                    onClick={handleSave}
                    endIcon={<SaveIcon fontSize="small" />}
                    variant="outlined"
                    color="primary"
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        ))}

      {ALLOWED_LOIS.filter((loi) => !formData[loi]).map((loi) => (
        <Box key={loi} sx={styles.loi} component={Paper} elevation={1}>
          <Typography variant="subtitle1" style={styles.loiTitle} gutterBottom>
            {loi}
          </Typography>

          <Box sx={styles.empty}>
            <Alert
              severity="info"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => handleAddLoiConf(loi)}
                >
                  Add configuration
                </Button>
              }
            >
              There is no configuration for <strong>{loi}</strong>.
            </Alert>
          </Box>
        </Box>
      ))}

      {!!keyValuePair && (
        <AddKeyValuePairDialog
          loi={keyValuePair.loi}
          category={keyValuePair.category}
          index={keyValuePair.index}
          onCancel={() => setKeyValuePair(null)}
          onAdd={handleAddKeyValuePair}
        />
      )}

      {!!saveMutation.error && (
        <Dialog open={!!saveMutation.error}>
          <DialogTitle>Something went wrong. Please try again.</DialogTitle>

          <DialogContent>{saveMutation.error.message}</DialogContent>

          <DialogActions>
            <Button autoFocus onClick={() => saveMutation.reset()} variant="outlined">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default memo(CallRedirectingPolicy);
