import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import DisplayCallInfo from "../DisplayInfoBox";
import axios from "axios";
import * as R from "ramda";
import auth0Client from "../../../auth/Auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    border: "solid grey 2px",
    boxShadow: "5px 6px  #888888",
    width: 600,
  },
  accordion: {
    boxShadow: "none",
  },
}));

const DisplayExperiment = ({ queuingId }) => {
  const classes = useStyles();
  const [recordingLink, setRecordingLink] = useState();

  useEffect(() => {
    const getRecordingLink = async () => {
      try {
        const result = await axios.post(
          process.env.REACT_APP_BACKEND + "outbound_auto/recording/link",
          { queuing_id: R.trim(queuingId) },
          {
            headers: { Authorization: `Bearer ${auth0Client.getIdToken()}` },
          },
        );
        setRecordingLink(R.path(["data", "link"], result));
      } catch (err) {
        setRecordingLink(null);
      }
    };
    getRecordingLink();
  }, [queuingId]);

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center">
      <Grid container justifyContent="center">
        <Paper className={classes.paper}>
          <DisplayCallInfo
            title="Experiment Info"
            data={[
              {
                field: "Queuing ID",
                value: queuingId,
              },
              R.isNil(recordingLink)
                ? {
                    field: "Lead Call Recording",
                    value: "No recording found",
                  }
                : {
                    field: "Lead Call Recording",
                    type: "audio",
                    link: recordingLink,
                  },
            ]}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DisplayExperiment;
