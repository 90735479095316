import React, { useEffect } from "react";
import auth0Client from "../auth/Auth";
import Loading from "../effects/Loading";

export default function Callback(props) {
  useEffect(() => {
    if (!auth0Client.isAuthenticated()) {
      console.log("Handle authentication");
      auth0Client
        .handleAuthentication()
        .then((redirectUri) => props.history.replace(redirectUri));
    } else {
      console.log("User Not authenticated");
      props.history.replace("/");
    }
  });

  return <Loading />;
}
