export const twilioErrorExplanations = {
  "no-answer": {
    description:
      "'no-answer' usually means the call failed due to networking issues, such as firewall, VPN, or unstable network connection",
    link: "https://assuranceiq.atlassian.net/wiki/spaces/TWL/pages/1044414512/Delta+Troubleshooting#Dialer-Connectivity-Issues",
  },
  busy: {
    description:
      "'busy' usually means the call failed due to headset misconfiguration issues",
    link: "https://assuranceiq.atlassian.net/wiki/spaces/EN/pages/2754740727/Dialer+Headset+Issues+Troubleshooting+Guide",
  },
  failed: {
    description:
      "'failed' means the call failed due to an unreachable destination or incorrect number",
    link: "https://assuranceiq.atlassian.net/wiki/spaces/TWL/pages/1044414512/Delta+Troubleshooting",
  },
  canceled: {
    description:
      "Prior to being answered, an outbound call was canceled via an HTTP POST request to the REST API, or an incoming call was disconnected by the calling party",
    link: "https://assuranceiq.atlassian.net/wiki/spaces/TWL/pages/1044414512/Delta+Troubleshooting",
  },
};
