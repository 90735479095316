import React from "react";
import * as R from "ramda";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { callRoutingDocumentUrl } from "../../../lib/utils";

const DATE_WIDTH = 0.15;
const BarDiv = styled.div`
  min-width: 600px;
  border-radius: 10px;
  padding: 10px;
`;

const BarList = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 5px;
  padding-right: 5px;
  li {
    margin: 5px 0;
  }

  li > div {
    width: 70%;
    display: inline-flex;
    padding: 3px 0;
  }

  li > span {
    display: inline-flex;
    vertical-align: text-bottom;
    width: 20%;
  }
`;

const Bar = styled.div`
  height: 20px;
  position: relative;
  background-color: ${(props) => props.color};
  width: ${(props) => props.width * 100}%;
  left: ${(props) => props.start * 100}%;
`;

const Dates = styled.div`
  padding: 0;
  margin: 0;
`;

const Date = styled.span`
  position: relative;
  font-size: smaller;
  left: ${(props) => props.start * 100}%;
`;

const LegendColor = styled.div`
  height: 10px;
  width: 10px;
  margin: 5px;
  background-color: ${(props) => props.color};
  display: inline-flex;
`;

const LegendDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  left: 70%;
  width: 30%;
`;

const LegendEntryDiv = styled.div`
  width: 100%;
`;

const computeEventStartPosition = (eventStart) =>
  eventStart < 0.5 ? eventStart : eventStart - DATE_WIDTH / 2;

const computeParticipantLeavePosition = (eventStart, eventWidth) =>
  eventStart + eventWidth - 0.15;

const DateLabels = ({ event }) => {
  const eventStart = R.prop("start", event);
  const participantJoinTime = R.prop("startTime", event);
  const participantLeaveTime = R.prop("endTime", event);

  return (
    <Dates>
      {event.width - DATE_WIDTH < 0 || R.equals(event.width, NaN) ? (
        <Date start={computeEventStartPosition(eventStart)}>
          {participantJoinTime} - {participantLeaveTime}
        </Date>
      ) : (
        <React.Fragment>
          <Date start={eventStart}>{participantJoinTime}</Date>
          <Date
            start={computeParticipantLeavePosition(eventStart, R.prop("width", event))}
          >
            {participantLeaveTime}
          </Date>
        </React.Fragment>
      )}
    </Dates>
  );
};

const Event = ({ event }) => {
  const routingId = R.prop("routingId", event);
  const eventName = R.prop("name", event);
  return (
    <React.Fragment>
      <li>
        <span />
        <DateLabels event={event} />
      </li>
      <li>
        <span>
          {routingId ? (
            <Link to={callRoutingDocumentUrl(routingId)}>{eventName}</Link>
          ) : (
            eventName
          )}
        </span>
        <div>
          <Bar width={event.width} start={event.start} color={event.color} />
        </div>
      </li>
    </React.Fragment>
  );
};

const generateLegends = R.pipe(
  R.values,
  R.uniqBy(R.prop("color")),
  R.map((leg) => (
    <LegendEntryDiv key={leg.color}>
      <LegendColor color={leg.color} col />
      <span>{leg.legend}</span>
    </LegendEntryDiv>
  )),
);

const Legend = ({ data }) => {
  return <LegendDiv>{generateLegends(data)}</LegendDiv>;
};
const generateEvents = (data) =>
  data.map((event) => <Event key={`${event.name}_${event.startTime}`} event={event} />);

const Chart = ({ data }) => {
  return (
    <BarDiv>
      <BarList>{generateEvents(data)}</BarList>
      <Legend data={data} />
    </BarDiv>
  );
};

export default Chart;
