import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Cancel, Edit, Save } from "@material-ui/icons";
import { assoc, equals, identity, ifElse, is, join, not, toString } from "ramda";
import { useState } from "react";
import { isBoolean, isEmptyOrNil, isString } from "../../../lib/utils";
import { renderLineOfInsuranceField, renderUserIdField } from "./utils";

const boolToString = ifElse(isBoolean, toString, identity);

const VersionFilters = (props) => {
  const { index, isDefaultVersion, version } = props;
  const [edit, setEdit] = useState();
  const [error, setError] = useState({});
  const [commitHash, setHash] = useState(version.version);
  const [comment, setComment] = useState(version.comment || "");
  const [attribute, setAttribute] = useState(Object.keys(version.filters)[0]);
  const [notify, setNotify] = useState(version.notify || false);
  const [filterValue, setFilterValue] = useState(
    boolToString(version.filters[Object.keys(version.filters)[0]]),
  );

  const getFilterValue = () => {
    if (edit) {
      if (equals(attribute, "user_ids")) {
        return renderUserIdField(filterValue, setFilterValue);
      } else if (equals(attribute, "line_of_insurance")) {
        return renderLineOfInsuranceField(filterValue, setFilterValue);
      } else {
        return (
          <TextField
            placeholder={attribute}
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            error={error["filterValue"] ? true : false}
            helperText={error["filterValue"]}
          />
        );
      }
    }

    return ifElse(is(Array), join(", "), identity)(filterValue);
  };

  const getVersionCell = () => {
    return commitHash;
  };

  const updateAttribute = (e) => {
    const value = e.target.value;
    if (["line_of_insurance", "user_ids"].includes(value)) {
      setFilterValue([]);
    } else if (["line_of_insurance", "user_ids"].includes(attribute)) {
      setFilterValue("");
    }
    setAttribute(value);
  };

  const validateForm = () => {
    setError({});
    let retVal = true;

    if (isDefaultVersion && notifyChanged() && commitHash === version.version) {
      return true;
    }

    if (isEmptyOrNil(comment)) {
      setError(assoc("comment", "Comment is required", error));
      retVal = false;
    }
    if (isEmptyOrNil(commitHash)) {
      setError(assoc("commitHash", "Version is required", error));
      retVal = false;
    }
    if (isEmptyOrNil(attribute)) {
      setError(assoc("attribute", "Attribute is required", error));
      retVal = false;
    }
    if (isEmptyOrNil(filterValue)) {
      setError(assoc("comment", "Filter Value is required", error));
      retVal = false;
    }
    return retVal;
  };

  const save = () => {
    let filters = {};
    filters[attribute] =
      isString(filterValue) && ["true", "false"].includes(filterValue.toLowerCase())
        ? equals(filterValue.toLowerCase(), "true")
        : filterValue;
    props.onSave(index, filters, comment, notify);
    setEdit(false);
  };

  const resetState = () => {
    setHash(version.version);
    setComment(version.comment || "");
    setAttribute(Object.keys(version.filters)[0]);
    setFilterValue(version.filters[Object.keys(version.filters)[0]]);
    setNotify(version.notify || false);
    setEdit(false);
    setError({});
  };

  const notifyChanged = () => {
    return !!version.notify !== notify;
  };

  return (
    <div key={`${version.version}-${index}`} style={{ width: "100%" }}>
      <TableContainer className="tableWrapper">
        <Table stickyHeader>
          <TableBody>
            <TableRow>
              <TableCell variant="head">Commit Hash</TableCell>
              <TableCell>{getVersionCell()}</TableCell>
              {not(isDefaultVersion) && (
                <>
                  <TableCell variant="head">Comment</TableCell>
                  <TableCell>
                    <TextField
                      value={comment}
                      disabled={!edit}
                      onChange={(e) => setComment(e.target.value)}
                      error={error["comment"] ? true : false}
                      helperText={error["comment"]}
                    />
                  </TableCell>
                </>
              )}
              {isDefaultVersion && (
                <>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </>
              )}
            </TableRow>

            {not(isDefaultVersion) && (
              <TableRow>
                <TableCell variant="head">Filter Attribute</TableCell>
                <TableCell>
                  <TextField
                    value={attribute}
                    disabled={!edit}
                    onChange={updateAttribute}
                    error={error["attribute"] ? true : false}
                    helperText={error["attribute"]}
                  />
                </TableCell>
                <TableCell variant="head">Filter Value</TableCell>
                <TableCell>{getFilterValue()}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={notify}
                  disabled={!edit && not(isDefaultVersion)}
                  onChange={({ target: { checked } }) => setNotify(checked)}
                ></Checkbox>
              }
              label="Notify"
            ></FormControlLabel>
          </FormGroup>
        </Box>
        {not(isDefaultVersion) && (
          <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
            {edit ? (
              <>
                <IconButton type="submit" onClick={resetState}>
                  <Cancel />
                </IconButton>
                <IconButton type="submit" onClick={() => validateForm() && save()}>
                  <Save />
                </IconButton>
              </>
            ) : (
              <IconButton type="submit" onClick={() => setEdit(true)}>
                <Edit />
              </IconButton>
            )}
          </Box>
        )}

        {isDefaultVersion && notifyChanged() && (
          <>
            <IconButton type="submit" onClick={resetState}>
              <Cancel />
            </IconButton>
            <IconButton type="submit" onClick={() => validateForm() && save()}>
              <Save />
            </IconButton>
          </>
        )}
      </TableContainer>
    </div>
  );
};

export default VersionFilters;
