import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },

  header: {
    margin: theme.spacing(4, 0),
    display: "flex",
    gap: theme.spacing(2),
  },

  avatar: {
    alignSelf: "center",
  },

  details: {
    flex: 1,
  },

  actions: {
    alignSelf: "center",
  },

  tabPanel: {
    flex: 1,
    marginTop: theme.spacing(2),
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
  },
}));

export default useStyles;
