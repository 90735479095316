import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },

  tabsWrapper: {
    display: "flex",
  },

  tab: {
    "&.Mui-selected": {
      backgroundColor: theme.palette.action.selected,
    },
  },

  tabPanel: {
    flex: 1,
    border: "1px solid",
    borderColor: theme.palette.divider,
  },

  tableWrapper: {
    border: "1px solid",
    borderColor: theme.palette.divider,
  },
}));

export default useStyles;
