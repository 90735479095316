import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
  },

  title: {
    marginBottom: theme.spacing(2),
  },

  skeleton: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },

  tabs: {
    marginTop: theme.spacing(4),
  },

  error: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
