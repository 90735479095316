import * as R from "ramda";
import React, { useState, memo } from "react";
import auth0Client from "../../../auth/Auth";
import DialingPools from "./DialingPools";
import PowerDialing from "./PowerDialing";
import LOIBalancing from "./LOIBalancing";
import SuccessIncrements from "./SuccessIncrements";
import DynamicControlForm from "../DynamicControlForm";
import ExtraLeadsPowerDialingConfig from "./ExtraLeadsPowerDialingConfig";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useMutation, useQuery } from "react-query";
import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import Tabs from "../../../lib/Tabs";
import useAxios from "../../../hooks/useAxios";
import CallRedirectingPolicy from "./CallRedirectingPolicy";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

const DialingConfiguration = () => {
  const classes = useStyles();
  const [simulatedAllocation, setSimulatedAllocation] = useState(null);
  const axios = useAxios();

  const query = useQuery(["dial-service-config"], () =>
    axios.post("get_dialing_service_config"),
  );

  const simulationMutation = useMutation(
    (params) =>
      axios.post("simulate_loi_balancing", {
        config_update: params,
      }),
    { onSuccess: setSimulatedAllocation },
  );

  const updateConfigMutation = useMutation(
    (params) =>
      axios.post("update_dialing_service_config", {
        config_update: params,
        user_id: auth0Client.getUserId(),
      }),
    { onSuccess: query.refetch },
  );

  const deltaConfig = query?.data?.data;

  return (
    <Container className={classes.root} maxWidth="md">
      <Tabs
        orientation="vertical"
        tabs={[
          {
            label: "Power Dialing",
            content: (
              <PowerDialing
                value={deltaConfig?.power_dialing?.config_value}
                handleSubmit={updateConfigMutation.mutate}
                isSubmitting={updateConfigMutation.isLoading}
              />
            ),
          },

          {
            label: "Dialing Pools",
            content: (
              <DialingPools
                value={deltaConfig?.dialing_pools_config?.dial_ratio}
                handleSubmit={updateConfigMutation.mutate}
                isSubmitting={updateConfigMutation.isLoading}
              />
            ),
          },

          {
            label: "Automated LOI Balancing (Intro Only!)",
            content: (
              <LOIBalancing
                outputAllocation={
                  deltaConfig?.automated_loi_balancing?.output_loi_allocation
                }
                linesOfInsurance={deltaConfig?.automated_loi_balancing?.insurance_lines}
                handleSubmit={updateConfigMutation.mutate}
                handleSimulate={simulationMutation.mutate}
                loading={query.isLoading}
                isSubmitting={updateConfigMutation.isLoading}
                isSimulating={simulationMutation.isLoading}
                simulatedAllocation={simulatedAllocation?.data}
                refetch={query.refetch}
              />
            ),
          },

          {
            label: "Dynamic Agent Success Rate Increments",
            content: (
              <SuccessIncrements
                data={R.omit(
                  ["creation_date_epoch", "config", "config_param", "config_value"],
                  deltaConfig?.["dynamic_agent_success_rate_increment"],
                )}
                handleSubmit={updateConfigMutation.mutate}
                isSubmitting={updateConfigMutation.isLoading}
              />
            ),
          },

          {
            label: "Extra Leads Power Dialing Config",
            content: (
              <ExtraLeadsPowerDialingConfig
                value={deltaConfig?.extra_leads_power_dialing_config?.config_value}
                handleSubmit={updateConfigMutation.mutate}
                isSubmitting={updateConfigMutation.isLoading}
              />
            ),
          },
          {
            label: "Call Redirecting Policy",
            content: (
              <CallRedirectingPolicy
                value={deltaConfig?.call_redirection}
                refetchConfig={() => query.refetch()}
              />
            ),
          },
          {
            label: "Outbound Express",
            content: (
              <DynamicControlForm
                config={deltaConfig || {}}
                handleSubmit={updateConfigMutation.mutate}
                isSubmitting={updateConfigMutation.isLoading}
                config_key={"outbound_express"}
              />
            ),
          },
        ]}
      />

      <Snackbar
        open={updateConfigMutation.isSuccess}
        autoHideDuration={5000}
        onClose={updateConfigMutation.reset}
        message="Config updated"
      >
        <Alert severity="success">Config has been updated</Alert>
      </Snackbar>

      <Snackbar
        open={updateConfigMutation.isError}
        autoHideDuration={5000}
        onClose={updateConfigMutation.reset}
      >
        <Alert severity="error" className={classes.error}>
          <AlertTitle>{updateConfigMutation.error?.code}</AlertTitle>
          {updateConfigMutation.error?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default memo(DialingConfiguration);
