import React from "react";
import SearchBox from "./SearchBox";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import DisplayExperiment from "./DisplayExperiment";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "80vh",
  },
}));

const OutboundAutoExperiments = () => {
  const classes = useStyles();
  const { queuingId } = useParams();

  return (
    <Grid
      spacing={4}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <Typography align="center" variant="h5">
          Queuing ID
        </Typography>
      </Grid>
      <Grid item>
        <SearchBox />
      </Grid>
      {queuingId && (
        <Grid item>
          <DisplayExperiment queuingId={queuingId} />
        </Grid>
      )}
    </Grid>
  );
};
export default OutboundAutoExperiments;
