import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import auth0Client from "../../../auth/Auth";
import ErrorDialog from "../../../lib/ErrorDialog";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import SubmitButton from "./SubmitButton";
import * as R from "ramda";
import { formattedPhoneNumber, formattedPhoneNumberNoCode } from "../../../lib/utils";
import { PhoneNumberMask, checkNumberValidity } from "../../../lib/PhoneNumberMask";
import { SuccessTypography } from "../../../lib/typographyUtils";

const useStyles = makeStyles((theme) => ({
  container: {
    border: "grey 2px solid",
    borderRadius: 5,
    boxShadow: 2,
    minHeight: 40,
    maxWidth: 290,
    margin: "auto",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRight: "solid grey 2px",
  },
  width: {},
}));

const SubmitBox = (props) => {
  const [error, setError] = React.useState();
  const [phoneNumberId, setPhoneNumberId] = React.useState();
  const [currentText, setCurrentText] = React.useState("(___) ___-____");
  const [submitting, setSubmitting] = React.useState(false);
  const [submitSuccessful, setSubmitSucessful] = React.useState("");

  const submitWhitelistNumber = async (phoneNumber) => {
    if (!phoneNumber) {
      return;
    }
    setSubmitting(true);
    try {
      await axios.post(
        process.env.REACT_APP_BACKEND + "whitelist_number",
        { lead_number: formattedPhoneNumber(phoneNumber) },
        {
          headers: { Authorization: `Bearer ${auth0Client.getIdToken()}` },
        },
      );
      setSubmitSucessful(`You have successfully whitelisted the number: ${phoneNumber}`);
      setPhoneNumberId();
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError({
          title: "Couldn't whitelist the phone number",
          message: `Couldn't whitelist phone number: ${phoneNumber}`,
        });
      } else {
        setError({
          title: "Couldn't connect to server",
          message: "Please check your internet connection.",
        });
      }
    }
    setSubmitting(false);
  };

  const classes = useStyles();

  const handleChange = (e) => {
    if (R.not(R.equals(e.target.value, currentText))) {
      setCurrentText(e.target.value);
      setPhoneNumberId(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    submitWhitelistNumber(phoneNumberId);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        {error && <ErrorDialog error={error} setError={setError} />}
        <Grid
          container
          className={classes.container}
          direction="row"
          alignItems="center"
          spacing={0}
        >
          <FormControl className={classes.textField}>
            <Input
              disableUnderline
              onChange={handleChange}
              value={phoneNumberId}
              inputComponent={PhoneNumberMask}
            />
          </FormControl>
          <SubmitButton
            searching={submitting}
            disabled={R.not(
              checkNumberValidity(
                formattedPhoneNumberNoCode(R.defaultTo("0", phoneNumberId)) || submitting,
              ),
            )}
          />
        </Grid>
      </form>
      {!R.isEmpty(submitSuccessful) ? (
        <Grid item>
          <SuccessTypography variant="subtitle1" align="center">
            {submitSuccessful}
          </SuccessTypography>
        </Grid>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default SubmitBox;
