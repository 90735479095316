import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Button,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import * as R from "ramda";
import useStyles from "./styles";

const validationSchema = yup.object({
  dynamic_agent_success_rate_increment: yup.lazy((obj) =>
    yup.object(
      R.map(() => {
        return yup.number("Number").min(0, "Success Increment must be positive");
      }, obj),
    ),
  ),
});

const SuccessIncrements = (props) => {
  const { data, handleSubmit, isSubmitting } = props;
  const classes = useStyles();

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      dynamic_agent_success_rate_increment: data,
    },
    mode: "onChange",
  });

  return data ? (
    <Box className={classes.datatable}>
      <Typography variant="subtitle2">
        The agent success range increment determines the amount that each level of agent
        success improves the percentage of calls they get.
      </Typography>

      <Typography variant="caption">
        The appropriate range for success rate increments varies between lines of
        insurance so be sure to consult Data Science if you don't know the appropriate
        range.
      </Typography>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Line of Insurance</TableCell>
              <TableCell align="right">Success Increment</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(data).map((key) => (
              <TableRow key={key}>
                <TableCell>{key}</TableCell>
                <TableCell align="right">
                  <Controller
                    control={form.control}
                    name={`dynamic_agent_success_rate_increment.${key}`}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        size="small"
                        inputProps={{
                          precision: 2,
                          step: 0.01,
                          inputMode: "decimal",
                        }}
                        type="number"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        className={classes.numberInput}
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box className={classes.buttons}>
        <Button
          onClick={form.handleSubmit(handleSubmit)}
          disabled={!form.formState.isDirty || !form.formState.isValid || isSubmitting}
          variant="contained"
          color="primary"
        >
          Update
        </Button>
      </Box>
    </Box>
  ) : null;
};

export default SuccessIncrements;
