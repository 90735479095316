import auth0 from "auth0-js";
import jwt_decode from "jwt-decode";
import { AUTH_CONFIG } from "./auth0-variables";
import * as R from "ramda";
import { guuid } from "../lib/utils";

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: AUTH_CONFIG.domain,
      clientID: AUTH_CONFIG.clientId,
      redirectUri: AUTH_CONFIG.callbackUrl,
      responseType: "id_token",
      scope: "openid profile email",
    });
  }

  getIdToken = () => localStorage.getItem("id_token");

  getUserEmail = () => JSON.parse(localStorage.getItem("profile")).email;

  getUserId = () => JSON.parse(localStorage.getItem("profile")).nickname;

  signIn = () => {
    // Can be whatever random string. We may use some uuid or random generator
    const nonce = guuid();
    const state = JSON.stringify({ redirectUri: window?.location?.pathname });
    localStorage.setItem(nonce, state);
    return this.auth0.authorize({ nonce, state });
  };

  isAuthenticated = () => {
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  };

  hasAccess = R.pipe(
    R.intersection([
      "delta_admin",
      "developer",
      "product_manager",
      "sales_manager",
      "data_scientist",
      "compliance",
      "telephony_operator",
      "tier3_support",
      "tier1_support",
    ]),
    R.complement(R.isEmpty),
  );

  isAuthorized = () => {
    const payload = jwt_decode(localStorage.getItem("id_token"));
    const hasAccess = this.hasAccess(payload["http://assurance/user_roles"]);
    if (hasAccess) {
      this.roles = payload["http://assurance/user_roles"];
      this.email = payload.email;
      return true;
    }
    return false;
  };

  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      let redirectUri;
      this.auth0.parseHash((err, authResult) => {
        console.log("Error", err);
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        console.log("Setting roles in local storage");

        const state = localStorage.getItem(authResult.idTokenPayload.nonce);
        if (state !== null) {
          localStorage.removeItem(authResult.idTokenPayload.nonce);
          redirectUri = JSON.parse(state)?.redirectUri;
        } else {
          return reject("Invalid nonce");
        }

        localStorage.setItem("access_token", authResult.accessToken);
        localStorage.setItem("id_token", authResult.idToken);
        localStorage.setItem("profile", JSON.stringify(authResult.idTokenPayload));
        localStorage.setItem("expires_at", authResult.idTokenPayload.exp * 1000);
        localStorage.setItem("email", authResult.email);
        resolve(redirectUri);
      });
    });
  };

  signOut = () => {
    const toRemove = ["access_token", "profile", "id_token", "expires_at", "roles"];
    R.map((x) => localStorage.removeItem(x), toRemove);
  };
}

const auth0Client = new Auth();
export default auth0Client;
