import React from "react";
import MaskedInput from "react-text-mask";
import * as R from "ramda";

const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const stripPhoneNumber = R.replace(/[^\d]/g, "");
const isRegex = R.pipe(R.type, R.equals("RegExp"));

const validPhoneNumberLength = R.pipe(R.filter(isRegex), R.length)(phoneNumberMask);

export const checkNumberValidity = R.pipe(
  stripPhoneNumber,
  R.length,
  R.equals(validPhoneNumberLength),
);
export function PhoneNumberMask({ inputRef, ...other }) {
  return (
    <MaskedInput
      style={{ textAlign: "center", width: 125 }}
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={phoneNumberMask}
      placeholderChar="_"
      showMask
    />
  );
}
