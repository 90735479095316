import { useMutation, useQuery } from "react-query";
import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  MenuItem,
  LinearProgress,
  Snackbar,
} from "@material-ui/core";
import {
  Alert,
  AlertTitle,
  Skeleton,
  SpeedDial,
  SpeedDialAction,
} from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { transformRole } from "../../../lib/utils";
import useAxios from "../../../hooks/useAxios";
import DispositionRole from "./DispositionRole";
import Tabs from "../../../lib/Tabs";
import useStyles from "./styles";

const dispositionSchema = yup
  .object({
    role: yup.string().required(),
    name: yup.string().required(),
    code: yup.number().positive().integer().required(),
  })
  .required();

const Dispositions = () => {
  const classes = useStyles();
  const axios = useAxios();
  const query = useQuery(["dispositions"], () => axios.post("get_dispositions"), {
    retry: false,
  });
  const [currentTab, setCurrentTab] = React.useState("0");
  const [edit, setEdit] = React.useState(false);
  const [showAdd, setShowAdd] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const canEdit = true; //routeAuthorizer.canUpdateDispositions();
  const addMutation = useMutation((params) =>
    axios.post("add_disposition", { add_disposition: params }),
  );
  const [snackbar, setSnackbar] = useState(null);
  const deleteMutation = useMutation((params) =>
    axios.post("remove_disposition", { remove_disposition: params }),
  );

  const addForm = useForm({
    resolver: yupResolver(dispositionSchema),
    defaultValues: {
      role: currentTab,
      name: "",
      code: "",
    },
  });

  const deleteForm = useForm({
    resolver: yupResolver(dispositionSchema),
    defaultValues: {
      role: "",
      name: "",
      code: "",
    },
  });

  useEffect(() => {
    if (addMutation.status === "success") {
      setShowAdd(false);
      setSnackbar("Disposition Added");
    }
  }, [addMutation.status]);

  useEffect(() => {
    if (deleteMutation.status === "success") {
      setSnackbar("Disposition Deleted");
    }
  }, [deleteMutation.status]);

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
    addForm.setValue("role", newValue);
  };

  const dispositions = query?.data?.data || {};

  useEffect(() => {
    if (!showAdd) {
      addForm.reset({
        role: currentTab,
        name: "",
        code: "",
      });
    }
  }, [showAdd]);

  const [roleToDelete, nameToDelete] = deleteForm.watch(["role", "name"]);

  useEffect(() => {
    if (roleToDelete && nameToDelete) {
      deleteForm.setValue("name", "");
      deleteForm.setValue("code", "");
    }
  }, [roleToDelete]);

  useEffect(() => {
    if (roleToDelete && nameToDelete) {
      deleteForm.setValue("code", dispositions[roleToDelete][nameToDelete]);
    }
  }, [roleToDelete, nameToDelete]);

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const toggleAdd = () => {
    setShowAdd(!showAdd);
  };

  const toggleDelete = () => {
    setShowDelete(!showDelete);
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      {query.isLoading && <Skeleton variant="rect" />}

      {query.status === "success" && (
        <Tabs
          className={classes.tabs}
          tabs={Object.keys(dispositions)
            .sort()
            .map((role, index) => ({
              label: transformRole(role),
              content: <DispositionRole data={dispositions[role]} />,
            }))}
          orientation="vertical"
        />
      )}

      {query.error && (
        <Alert severity="error" className={classes.error}>
          <AlertTitle>An error occurred while fetching dispositions</AlertTitle>
          <span>{query.error?.code}</span>: {query.error?.message}
        </Alert>
      )}

      {canEdit && (
        <SpeedDial
          ariaLabel="Edit Dispositions"
          className={classes.addButton}
          onClose={toggleEdit}
          onOpen={toggleEdit}
          open={edit}
          aria-label="edit"
          variant="extended"
          color="primary"
          icon={<EditIcon />}
        >
          <SpeedDialAction
            icon={<AddIcon />}
            tooltipTitle="Add"
            tooltipOpen
            onClick={toggleAdd}
          />
          <SpeedDialAction
            icon={<DeleteIcon />}
            tooltipTitle="Delete"
            tooltipOpen
            onClick={toggleDelete}
          />
        </SpeedDial>
      )}

      <Dialog
        className={classes.dialog}
        open={showAdd}
        onSubmit={addForm.handleSubmit(addMutation.mutate)}
        PaperProps={{ component: "form" }}
      >
        <DialogTitle>Add Disposition</DialogTitle>
        <DialogContent className={classes.addDialogContent}>
          <Controller
            control={addForm.control}
            name="role"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                select
                label="Role"
                variant="outlined"
                size="small"
                className="role"
                onBlur={field.onBlur}
                value={field.value}
                error={!!fieldState.error}
                InputLabelProps={{ shrink: true }}
              >
                {Object.keys(dispositions)
                  .sort()
                  .map((role) => (
                    <MenuItem key={role} value={role}>
                      {transformRole(role)}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />

          <Controller
            control={addForm.control}
            name="name"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Name"
                variant="outlined"
                size="small"
                className="name"
                onBlur={field.onBlur}
                value={field.value}
                error={!!fieldState.error}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />

          <Controller
            control={addForm.control}
            name="code"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                variant="outlined"
                label="Code"
                size="small"
                className="code"
                onBlur={field.onBlur}
                value={field.value}
                error={!!fieldState.error}
                type="number"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />

          {addMutation.isLoading && <LinearProgress />}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setShowAdd(false)}>Cancel</Button>
          <Button type="submit" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        className={classes.dialog}
        open={showDelete}
        onSubmit={deleteForm.handleSubmit(deleteMutation.mutate)}
        PaperProps={{ component: "form" }}
      >
        <DialogTitle>Delete Disposition</DialogTitle>
        <DialogContent className={classes.addDialogContent}>
          <Controller
            control={deleteForm.control}
            name="role"
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                select
                label="Role"
                variant="outlined"
                size="small"
                className="role"
                onBlur={field.onBlur}
                value={field.value}
                error={!!fieldState.error}
                InputLabelProps={{ shrink: true }}
              >
                {Object.keys(dispositions)
                  .sort()
                  .map((role) => (
                    <MenuItem key={role} value={role}>
                      {transformRole(role)}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />

          {!!roleToDelete && (
            <>
              <Controller
                control={deleteForm.control}
                name="name"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    select
                    label="Name"
                    variant="outlined"
                    size="small"
                    className="name"
                    onBlur={field.onBlur}
                    value={field.value}
                    error={!!fieldState.error}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disable: true }}
                  >
                    {Object.keys(dispositions[roleToDelete]).map((dispositionName) => (
                      <MenuItem key={dispositionName} value={dispositionName}>
                        {dispositionName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              <Controller
                control={deleteForm.control}
                name="code"
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    label="Code"
                    size="small"
                    className="code"
                    onBlur={field.onBlur}
                    value={field.value}
                    error={!!fieldState.error}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                )}
              />
            </>
          )}

          {deleteMutation.isLoading && <LinearProgress />}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setShowDelete(false)}>Cancel</Button>
          <Button type="submit" color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!snackbar}
        autoHideDuration={3000}
        message={snackbar}
        onClose={() => setSnackbar(null)}
      />
    </Container>
  );
};

export default Dispositions;
