import React from "react";
import * as R from "ramda";
import * as moment from "moment-timezone";
import { Grid, Typography, Paper } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { makeStyles } from "@material-ui/core/styles";
import DisplayInfoBox from "../DisplayInfoBox";
import {
  createWidgetAttributes,
  getTransformedLeadInfo,
  createTimeLocalizer,
  createTimeFormatter,
} from "../../../lib/utils";

const useStyles = makeStyles({
  timeline: {
    marginTop: 1,
  },
  leadInfoContainer: {
    paddingTop: 20,
  },
});

const sentenceCasedEvents = {
  join_time_epoch: "Join Time",
  leave_time_epoch: "Leave Time",
  ringing: "Ringing",
  canceled: "Canceled",
  completed: "Completed",
};

const timeZone = moment.tz.guess(true);
const timeLocalizer = createTimeLocalizer(timeZone);
const timeFormatter = createTimeFormatter(timeLocalizer);
const hasCustomerLeft = R.equals("leave_time_epoch");

const Event = (event) => {
  var displayTimelineConnector = true;
  const eventName = R.prop("name", event);

  if (hasCustomerLeft(eventName)) {
    displayTimelineConnector = false;
  }

  return (
    <Grid key={eventName}>
      <TimelineItem>
        <TimelineOppositeContent>
          {R.prop(eventName, sentenceCasedEvents)}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          {displayTimelineConnector && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Typography>{timeFormatter(R.prop("epoch", event))}</Typography>
        </TimelineContent>
      </TimelineItem>
    </Grid>
  );
};

const LeadInfo = ({ lead }) => {
  const transformLeadEvents = R.pipe(
    R.propOr([], "leadEvents"),
    R.filter(({ name }) => name !== "leave_sequence_number"),
  );
  const classes = useStyles();
  const leadEvents = transformLeadEvents(lead);
  const leadInfo = getTransformedLeadInfo({ isMaestroRedirect: true, lead });

  return (
    <Grid container>
      <Grid item xs={6}>
        <DisplayInfoBox data={createWidgetAttributes(leadInfo)} />
      </Grid>
      <Grid item xs={6}>
        <Timeline className={classes.timeline}>{R.map(Event, leadEvents)}</Timeline>
      </Grid>
    </Grid>
  );
};

export default LeadInfo;
