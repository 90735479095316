import React, { useState } from "react";
import * as R from "ramda";
import * as moment from "moment-timezone";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ConferenceTimeline from "./ConferenceTimeline";
import DisplayCallInfo from "../DisplayInfoBox";
import {
  createAlphabeticalWidgetAttributes,
  getTransformedLeadInfo,
} from "../../../lib/utils";
import { generateCallInfo, generateDevInfo } from "./generateInfo";
import TimeZonePicker from "../../../lib/TimeZonePicker";

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  tab: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.71,
    minWidth: "auto",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textTransform: "none",
    "& + &": {
      marginLeft: 24,
    },
  },

  tabContent: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },

  tabTitle: {
    marginBottom: theme.spacing(2),
  },
}));

const createTimeLocalizer = (timeZone) => (epoch) => moment.unix(epoch).tz(timeZone);

const createTimeFormatter = (timeLocalizer) => (time) =>
  timeLocalizer(time).format("HH:mm:ss");

const DisplayCallDoc = ({ callDoc, className }) => {
  const [timeZone, setTimeZone] = useState(moment.tz.guess());
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState("Conference");

  const timeLocalizer = createTimeLocalizer(timeZone);
  const timeFormatter = createTimeFormatter(timeLocalizer);

  const buyerInfo = R.prop("buyerInfo", callDoc);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const tabs = {
    Conference: {
      content: (
        <>
          <ConferenceTimeline
            conference={callDoc.conference}
            timeFormatter={timeFormatter}
          />
          <DisplayCallInfo
            data={generateCallInfo(callDoc, timeLocalizer)}
            timeLocalizer={timeLocalizer}
          />
        </>
      ),
      visible: !!callDoc?.conference,
    },

    Buyer: {
      content: (
        <DisplayCallInfo
          data={createAlphabeticalWidgetAttributes(buyerInfo)}
          timeLocalizer={timeLocalizer}
        />
      ),
      visible: !!callDoc?.buyerInfo,
    },

    Shopper: {
      content: (
        <DisplayCallInfo
          data={createAlphabeticalWidgetAttributes(
            getTransformedLeadInfo({
              isMaestroRedirect: false,
              lead: R.prop("leadInfo", callDoc),
            }),
          )}
          timeLocalizer={timeLocalizer}
        />
      ),
      visible: !!callDoc?.leadInfo,
    },

    Agent: {
      content: (
        <DisplayCallInfo
          data={createAlphabeticalWidgetAttributes(R.prop("agentInfo", callDoc))}
          timeLocalizer={timeLocalizer}
        />
      ),
      visible: !!callDoc?.agentInfo,
    },

    Developer: {
      content: <DisplayCallInfo data={generateDevInfo(callDoc)} />,
      visible: !!callDoc?.devInfo,
    },
  };

  return (
    <Box className={clsx(className, classes.root)}>
      <TimeZonePicker timeZone={timeZone} setTimeZone={setTimeZone} />

      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        textColor="primary"
        value={currentTab}
        variant="scrollable"
        className={classes.tabs}
      >
        {Object.keys(tabs)
          .filter((tab) => tabs[tab].visible)
          .map((tab) => (
            <Tab className={classes.tab} key={tab} label={tab} value={tab} />
          ))}
      </Tabs>

      <Paper className={classes.tabContent}>{tabs[currentTab].content}</Paper>
    </Box>
  );
};

export default DisplayCallDoc;
