import { useMemo } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import auth0Client from "../auth/Auth";
import { equals } from "ramda";

const useAxios = () => {
  const token = auth0Client.getIdToken();
  const axios = useMemo(() => {
    const axios = Axios.create({
      baseURL: process.env.REACT_APP_BACKEND,
      headers: {
        "Content-Type": "application/json",
      },
    });

    axios.interceptors.request.use((config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response && equals(error.response.status, 401)) {
          toast["error"]("Unauthorized Request", { autoClose: false });
        }
        return Promise.reject(error);
      },
    );

    return axios;
  }, [token]);
  return axios;
};

export default useAxios;
