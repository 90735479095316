import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { equals, is, isEmpty, not } from "ramda";
import { useState } from "react";
import { isString } from "../../../lib/utils";
import { renderLineOfInsuranceField, renderUserIdField } from "./utils";

const NewFilterModal = (props) => {
  const { setAdd, avaialbleVersions, activeVersions, saveVersions } = props;
  const [version, setVersion] = useState(avaialbleVersions[0]);
  const [comment, setComment] = useState("");
  const [attribute, setAttribute] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [errors, setErrors] = useState({});

  const getFilterValue = () => {
    if (equals(attribute, "user_ids")) {
      if (not(is(Array, filterValue))) {
        setFilterValue([]);
      }
      return renderUserIdField(filterValue, setFilterValue, errors["filterValue"]);
    } else if (equals(attribute, "line_of_insurance")) {
      return renderLineOfInsuranceField(
        filterValue,
        setFilterValue,
        errors["filterValue"],
      );
    } else {
      if (not(isString(filterValue))) {
        setFilterValue("");
      }
      return (
        <TextField
          fullWidth
          id="filterValue"
          variant="standard"
          label={attribute || "Filter Value"}
          value={filterValue}
          error={errors["filterValue"] ? true : false}
          onChange={(e) => setFilterValue(e.target.value)}
        />
      );
    }
  };

  const submit = () => {
    let versions = activeVersions;
    let filter = {};
    let formErrors = {};
    if (!comment) {
      formErrors["comment"] = "This field is required";
    }
    if (!version) {
      formErrors["version"] = "This field is required";
    }
    if (!attribute) {
      formErrors["attribute"] = "This field is required";
    }
    if (!filterValue) {
      formErrors["filterValue"] = "This field is required";
    }

    if (not(isEmpty(formErrors))) {
      setErrors(formErrors);
      return;
    }

    filter[attribute] =
      isString(filterValue) && ["true", "false"].includes(filterValue.toLowerCase())
        ? equals(filterValue.toLowerCase(), "true")
        : filterValue;
    versions.unshift({
      comment: comment,
      filters: filter,
      version: version,
    });
    saveVersions(versions);
    setAdd(false);
  };

  return (
    <>
      <DialogTitle>Add Filter</DialogTitle>
      <DialogContent>
        <DialogContentText>Select a version</DialogContentText>
        <Autocomplete
          autoFocus
          options={avaialbleVersions}
          id="version"
          label="Commit Hash"
          renderInput={(params) => (
            <TextField
              {...params}
              error={errors["version"] ? true : false}
              placeholder="Select Version"
            />
          )}
          value={version}
          onSelect={(e) => setVersion(e.target.value)}
          style={{ paddingBottom: 10 }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="comment"
          label="Comment"
          fullWidth
          variant="standard"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          style={{ paddingBottom: 10 }}
          error={errors["comment"] ? true : false}
        />

        <TextField
          autoFocus
          margin="dense"
          id="attribute"
          label="Attribute"
          onChange={(e) => setAttribute(e.target.value)}
          fullWidth
          variant="standard"
          error={errors["attribute"] ? true : false}
          style={{ paddingBottom: 10 }}
        />
        {getFilterValue()}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setAdd(false)}>Cancel</Button>
        <Button type="submit" color="primary" onClick={() => submit()}>
          Add
        </Button>
      </DialogActions>
    </>
  );
};

export default NewFilterModal;
