import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },

  searchBox: {
    marginBottom: theme.spacing(4),
  },

  info: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
