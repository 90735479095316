import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },

  title: {
    marginBottom: theme.spacing(2),
  },

  tableContainer: {
    marginBottom: 30,
    padding: theme.spacing(2),
    "& th, td": {
      fontSize: 15,
    },
  },
}));

export default useStyles;
