import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import SubmitBox from "./SubmitBox";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "80vh",
  },
}));

const Whitelist = () => {
  const classes = useStyles();

  return (
    <Grid
      spacing={4}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <Typography align="center" variant="h5">
          Whitelist Phone Number
        </Typography>
        <Typography align="center" variant="subtitle2">
          Numbers added to whitelist will be skipped when doing DNC check on outbound
          manual calls
        </Typography>
      </Grid>
      <Grid item>
        <SubmitBox />
      </Grid>
    </Grid>
  );
};
export default Whitelist;
