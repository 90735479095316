import React from "react";

export default function PageNotFound() {
  return (
    <div>
      <h1>404 Page not found</h1>
      <p>
        You have stumbled somewhere you shouldn't ! Use Navigation bar on the left to
        navigate to the page you are searching for.
      </p>
    </div>
  );
}
