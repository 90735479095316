import React from "react";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/material-ui";

const DynamicControlForm = (props) => {
  const { handleSubmit, isSubmitting, config, config_key } = props;

  const schema = config[config_key]?.schema || {};
  const data = config[config_key]?.data || {};

  return (
    <Form
      schema={schema}
      formData={data}
      validator={validator}
      onSubmit={(form) => handleSubmit({ [config_key]: form.formData })}
      disabled={isSubmitting}
    />
  );
};

export default DynamicControlForm;
