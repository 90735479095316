import React, { useState } from "react";
import { Container, Paper } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import camelcaseKey from "camelcase-keys";
import * as R from "ramda";
import * as moment from "moment-timezone";
import { Alert, AlertTitle, Skeleton } from "@material-ui/lab";
import SearchBox from "../../../lib/SearchBox";
import useAxios from "../../../hooks/useAxios";
import TimeZonePicker from "../../../lib/TimeZonePicker";
import { createTimeLocalizer } from "../../../lib/utils";
import { generatePhoneInfo } from "./generatePhoneInfo";
import DisplayInfoBox from "../DisplayInfoBox";
import useStyles from "./styles";

const PhoneInfo = () => {
  const [timeZone, setTimeZone] = useState(moment.tz.guess());
  const timeLocalizer = createTimeLocalizer(timeZone);
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const axios = useAxios();
  const { phoneNumber } = params;
  const query = useQuery(
    ["phoneNumber", phoneNumber],
    () => axios.post("get_phone_number_metadata", { phone_number: phoneNumber }),
    { enabled: !!phoneNumber },
  );
  const phoneMetadata = camelcaseKey(R.path(["data", "data"], query), { deep: true });

  return (
    <Container maxWidth="md" className={classes.root}>
      <SearchBox
        className={classes.searchBox}
        label="Phone Number"
        value={phoneNumber}
        onSearch={(value) => (value ? history.push(`/phone-info/${value}`) : null)}
        type="phone"
      />

      {query.isSuccess && (
        <TimeZonePicker timeZone={timeZone} setTimeZone={setTimeZone} />
      )}

      {query.isLoading ? (
        <Skeleton animation="wave" height={100} />
      ) : query.isSuccess ? (
        <Paper className={classes.info}>
          <DisplayInfoBox
            data={generatePhoneInfo(phoneMetadata, timeLocalizer)}
            timeLocalizer={timeLocalizer}
          />
        </Paper>
      ) : null}

      {query.isError && (
        <Alert severity="error" className={classes.error}>
          <AlertTitle>Failed to load phone number info</AlertTitle>
          {query.error?.message}
        </Alert>
      )}
    </Container>
  );
};
export default PhoneInfo;
