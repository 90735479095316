import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Typography, Box, TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import useStyles from "./styles";

const validationSchema = yup.object({
  extra_leads_power_dialing_config: yup.object({
    dial_ratio_scaling: yup.number().min(0).max(10),
    lead_ltv_upper_bound: yup.number().min(0).max(100),
  }),
});

const ExtraLeadsPowerDialingConfig = (props) => {
  const { value, handleSubmit, isSubmitting } = props;
  const classes = useStyles();

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      extra_leads_power_dialing_config: value,
    },
    mode: "onChange",
  });

  return (
    <Box className={classes.datatable}>
      <Typography variant="subtitle2">
        The extra leads power dialing implementation adds more lower LTV lead dials to
        dialing pool. The dial ratio scalar multiplies the dial ratio to determine the
        number of additional leads to dial. The extra leads to dial will have a LTV under
        the lead LTV limit, if there are such leads available to dial.
      </Typography>

      <Controller
        control={form.control}
        name="extra_leads_power_dialing_config.dial_ratio_scaling"
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Dial Ratio Scalar"
            type="number"
            size="small"
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            className={classes.numberInput}
          />
        )}
      />

      <Controller
        control={form.control}
        name="extra_leads_power_dialing_config.lead_ltv_upper_bound"
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label="Lead LTV Limit"
            type="number"
            size="small"
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            className={classes.numberInput}
          />
        )}
      />

      <Box>
        <Button
          onClick={form.handleSubmit(handleSubmit)}
          disabled={!form.formState.isValid || isSubmitting}
          variant="contained"
          color="primary"
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default ExtraLeadsPowerDialingConfig;
