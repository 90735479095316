import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider, hydrate, dehydrate } from "react-query";
import { ToastContainer } from "react-toastify";
import auth0Client from "./auth/Auth";
import Loading from "./effects/Loading";
import SignOut from "./auth/SignOut";
import Navigation from "./features/Navigation";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core";
import theme from "./theme";

const App = () => {
  const [queryClient] = React.useState(() => {
    const _queryClient = new QueryClient();

    const savedData = localStorage.getItem("react-query-state");
    if (savedData) {
      hydrate(_queryClient, JSON.parse(savedData));
    }

    return _queryClient;
  });

  useEffect(() => {
    const dehydrateState = () => {
      const state = dehydrate(queryClient);
      localStorage.setItem("react-query-state", JSON.stringify(state));
    };

    window.addEventListener("beforeunload", dehydrateState);
    return () => {
      window.removeEventListener("beforeunload", dehydrateState);
    };
  }, []);

  if (!auth0Client.isAuthenticated()) {
    auth0Client.signIn();
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer limit={1} />
        <CssBaseline />
        {auth0Client.isAuthorized() ? <Navigation /> : <SignOut />}
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
