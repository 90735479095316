import {
  TableContainer,
  Table,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Container,
} from "@material-ui/core";
import useStyles from "./styles";

const HOLIDAYS = {
  NewYearsDay: { label: "New Year's Day", date: "Jan 1" },
  MemorialDay: { label: "Memorial Day ", date: "May 29" },
  IndependenceDay: { label: "Independence Day", date: "July 4" },
  LaborDay: { label: "Labor Day", date: "First Monday in September" },
  Thanksgiving: { label: "Thanksgiving", date: "Fourth Thursday of November" },
  ChristmasEve: { label: "Christmas Eve", date: "Dec 24" },
  ChristmasDay: { label: "Christmas Day", date: "Dec 25" },
  NewYearsEve: { label: "New Year's Eve", date: "Dec 31" },
};

const LOI = {
  MEDICARE: "Medicare",
  LIFE: "Life",
  AUTO_HOME: "Auto/Home",
  HOME: "Home",
  HEALTH: "Health",
  MORTGAGE: "Mortgage",
};

const DAY = {
  WEEKDAYS: "Weekdays",
  SATURDAY: "Saturday",
  SUNDAY: "Sunday",
};

const DIALING_HOURS_CONFIG = {
  Eastern: {
    [LOI.MEDICARE]: {
      [DAY.WEEKDAYS]: "8am to 9pm",
      [DAY.SATURDAY]: "8am to 9pm",
      [DAY.SUNDAY]: "8am to 9pm",
    },
    "Medicare FL": {
      [DAY.WEEKDAYS]: "8am to 8pm",
      [DAY.SATURDAY]: "8am to 8pm",
      [DAY.SUNDAY]: "8am to 8pm",
    },
    [LOI.HEALTH]: {
      [DAY.WEEKDAYS]: "8am to 9pm",
      [DAY.SATURDAY]: "8am to 9pm",
      [DAY.SUNDAY]: "8am to 9pm",
    },
    "Health FL": {
      [DAY.WEEKDAYS]: "8am to 8pm",
      [DAY.SATURDAY]: "8am to 8pm",
      [DAY.SUNDAY]: "8am to 8pm",
    },
    "Health NY": {
      [DAY.WEEKDAYS]: "8am to 8pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
    "Health NJ": {
      [DAY.WEEKDAYS]: "8am to 8pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
    "Health MA": {
      [DAY.WEEKDAYS]: "8am to 8pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
    [LOI.LIFE]: {
      [DAY.WEEKDAYS]: "8am to 9pm",
      [DAY.SATURDAY]: "8am to 9pm",
      [DAY.SUNDAY]: "8am to 9pm",
    },
    "Life FL": {
      [DAY.WEEKDAYS]: "8am to 8pm",
      [DAY.SATURDAY]: "8am to 8pm",
      [DAY.SUNDAY]: "8am to 8pm",
    },
    [LOI.AUTO_HOME]: {
      [DAY.WEEKDAYS]: "9am to 8pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
    [LOI.MORTGAGE]: {
      [DAY.WEEKDAYS]: "10am to 9pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
    "Mortgage FL": {
      [DAY.WEEKDAYS]: "10am to 8pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
  },

  Central: {
    [LOI.MEDICARE]: {
      [DAY.WEEKDAYS]: "8am to 9pm",
      [DAY.SATURDAY]: "8am to 9pm",
      [DAY.SUNDAY]: "8am to 9pm",
    },
    "Medicare OK": {
      [DAY.WEEKDAYS]: "8am to 8pm",
      [DAY.SATURDAY]: "8am to 8pm",
      [DAY.SUNDAY]: "8am to 8pm",
    },
    [LOI.HEALTH]: {
      [DAY.WEEKDAYS]: "8am to 9pm",
      [DAY.SATURDAY]: "8am to 9pm",
      [DAY.SUNDAY]: "8am to 9pm",
    },
    "Health OK": {
      [DAY.WEEKDAYS]: "8am to 8pm",
      [DAY.SATURDAY]: "8am to 8pm",
      [DAY.SUNDAY]: "8am to 8pm",
    },
    "Health KS": {
      [DAY.WEEKDAYS]: "8am to 7pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
    [LOI.LIFE]: {
      [DAY.WEEKDAYS]: "8am to 9pm",
      [DAY.SATURDAY]: "8am to 9pm",
      [DAY.SUNDAY]: "8am to 9pm",
    },
    "Life OK": {
      [DAY.WEEKDAYS]: "8am to 8pm",
      [DAY.SATURDAY]: "8am to 8pm",
      [DAY.SUNDAY]: "8am to 8pm",
    },
    [LOI.AUTO_HOME]: {
      [DAY.WEEKDAYS]: "8am to 7pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
    [LOI.MORTGAGE]: {
      [DAY.WEEKDAYS]: "9am to 8pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
  },

  Mountain: {
    [LOI.MEDICARE]: {
      [DAY.WEEKDAYS]: "8am to 9pm",
      [DAY.SATURDAY]: "8am to 8pm",
      [DAY.SUNDAY]: "8am to 8pm",
    },
    [LOI.HEALTH]: {
      [DAY.WEEKDAYS]: "8am to 9pm",
      [DAY.SATURDAY]: "8am to 9pm",
      [DAY.SUNDAY]: "8am to 9pm",
    },
    [LOI.LIFE]: {
      [DAY.WEEKDAYS]: "8am to 9pm",
      [DAY.SATURDAY]: "8am to 9pm",
      [DAY.SUNDAY]: "8am to 9pm",
    },
    [LOI.AUTO_HOME]: {
      [DAY.WEEKDAYS]: "8am to 6pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
    [LOI.MORTGAGE]: {
      [DAY.WEEKDAYS]: "8am to 7pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
  },

  Pacific: {
    [LOI.MEDICARE]: {
      [DAY.WEEKDAYS]: "8am to 9pm",
      [DAY.SATURDAY]: "8am to 7pm",
      [DAY.SUNDAY]: "8am to 7pm",
    },
    "Medicare NV": {
      [DAY.WEEKDAYS]: "8am to 8pm",
      [DAY.SATURDAY]: "8am to 7pm",
      [DAY.SUNDAY]: "8am to 7pm",
    },
    [LOI.HEALTH]: {
      [DAY.WEEKDAYS]: "8am to 9pm",
      [DAY.SATURDAY]: "8am to 9pm",
      [DAY.SUNDAY]: "8am to 9pm",
    },
    "Health CA": {
      [DAY.WEEKDAYS]: "8am to 5pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
    "Health WA": {
      [DAY.WEEKDAYS]: "8am to 5pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
    [LOI.LIFE]: {
      [DAY.WEEKDAYS]: "8am to 9pm",
      [DAY.SATURDAY]: "8am to 9pm",
      [DAY.SUNDAY]: "8am to 9pm",
    },
    [LOI.AUTO_HOME]: {
      [DAY.WEEKDAYS]: "8am to 5pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
    [LOI.MORTGAGE]: {
      [DAY.WEEKDAYS]: "8am to 6pm",
      [DAY.SATURDAY]: "Off",
      [DAY.SUNDAY]: "Off",
    },
  },
};

const HOLIDAYS_BY_LOI = {
  [LOI.MEDICARE]: {
    ON: [
      HOLIDAYS.IndependenceDay,
      HOLIDAYS.Thanksgiving,
      HOLIDAYS.LaborDay,
      HOLIDAYS.MemorialDay,
    ],
    rule: "Follow weekend hours",
    OFF: [HOLIDAYS.NewYearsDay, HOLIDAYS.ChristmasDay],
  },

  [LOI.LIFE]: {
    ON: [
      HOLIDAYS.IndependenceDay,
      HOLIDAYS.Thanksgiving,
      HOLIDAYS.LaborDay,
      HOLIDAYS.MemorialDay,
      HOLIDAYS.NewYearsDay,
      HOLIDAYS.ChristmasDay,
    ],
    rule: "No change",
    OFF: [HOLIDAYS.NewYearsDay, HOLIDAYS.ChristmasDay],
  },

  [LOI.AUTO_HOME]: {
    ON: [
      HOLIDAYS.LaborDay,
      HOLIDAYS.MemorialDay,
      HOLIDAYS.ChristmasEve,
      HOLIDAYS.NewYearsEve,
    ],
    OFF: [
      HOLIDAYS.IndependenceDay,
      HOLIDAYS.Thanksgiving,
      HOLIDAYS.NewYearsDay,
      HOLIDAYS.ChristmasDay,
    ],
  },

  [LOI.HEALTH]: {
    ON: [
      HOLIDAYS.IndependenceDay,
      HOLIDAYS.Thanksgiving,
      HOLIDAYS.LaborDay,
      HOLIDAYS.MemorialDay,
    ],
    OFF: [HOLIDAYS.NewYearsDay, HOLIDAYS.ChristmasDay],
  },

  "Health CA": {
    ON: [],
    OFF: [
      HOLIDAYS.IndependenceDay,
      HOLIDAYS.Thanksgiving,
      HOLIDAYS.LaborDay,
      HOLIDAYS.MemorialDay,
      HOLIDAYS.NewYearsDay,
      HOLIDAYS.ChristmasDay,
    ],
  },

  "Health NY": {
    ON: [],
    OFF: [
      HOLIDAYS.IndependenceDay,
      HOLIDAYS.Thanksgiving,
      HOLIDAYS.LaborDay,
      HOLIDAYS.MemorialDay,
      HOLIDAYS.NewYearsDay,
      HOLIDAYS.ChristmasDay,
    ],
  },

  "Health NJ": {
    ON: [],
    OFF: [
      HOLIDAYS.IndependenceDay,
      HOLIDAYS.Thanksgiving,
      HOLIDAYS.LaborDay,
      HOLIDAYS.MemorialDay,
      HOLIDAYS.NewYearsDay,
      HOLIDAYS.ChristmasDay,
    ],
  },

  "Health MA": {
    ON: [],
    OFF: [
      HOLIDAYS.IndependenceDay,
      HOLIDAYS.Thanksgiving,
      HOLIDAYS.LaborDay,
      HOLIDAYS.MemorialDay,
      HOLIDAYS.NewYearsDay,
      HOLIDAYS.ChristmasDay,
    ],
  },

  "Health KS": {
    ON: [],
    OFF: [
      HOLIDAYS.IndependenceDay,
      HOLIDAYS.Thanksgiving,
      HOLIDAYS.LaborDay,
      HOLIDAYS.MemorialDay,
      HOLIDAYS.NewYearsDay,
      HOLIDAYS.ChristmasDay,
    ],
  },

  "Health WA": {
    ON: [],
    OFF: [
      HOLIDAYS.IndependenceDay,
      HOLIDAYS.Thanksgiving,
      HOLIDAYS.LaborDay,
      HOLIDAYS.MemorialDay,
      HOLIDAYS.NewYearsDay,
      HOLIDAYS.ChristmasDay,
    ],
  },

  [LOI.MORTGAGE]: {
    ON: [],
    OFF: [
      HOLIDAYS.NewYearsDay,
      HOLIDAYS.IndependenceDay,
      HOLIDAYS.Thanksgiving,
      HOLIDAYS.MemorialDay,
      HOLIDAYS.ChristmasEve,
      HOLIDAYS.ChristmasDay,
      HOLIDAYS.NewYearsEve,
    ],
  },
};

const DialingHours = () => {
  const classes = useStyles();

  const formatHolidays = (holidays) => (
    <div>
      {holidays.map((h) => (
        <div key={h.label}>{h.label}</div>
      ))}
    </div>
  );

  return (
    <Container className={classes.root} maxWidth="md">
      <Typography variant="h4" component="h1" className={classes.title}>
        Dialing hours by Timezone
      </Typography>
      {Object.keys(DIALING_HOURS_CONFIG).map((timezone) => (
        <TableContainer
          key={timezone}
          className={classes.tableContainer}
          component={Paper}
        >
          <Typography variant="subtitle1">{timezone}</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>LOI</TableCell>
                <TableCell align="right">Weekday</TableCell>
                <TableCell align="right">Saturday</TableCell>
                <TableCell align="right">Sunday</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(DIALING_HOURS_CONFIG[timezone]).map((LOF) => (
                <TableRow key={LOF}>
                  <TableCell component="th" scope="row">
                    {LOF}
                  </TableCell>
                  <TableCell align="right">
                    {DIALING_HOURS_CONFIG[timezone][LOF][DAY.WEEKDAYS]}
                  </TableCell>
                  <TableCell align="right">
                    {DIALING_HOURS_CONFIG[timezone][LOF][DAY.SATURDAY]}
                  </TableCell>
                  <TableCell align="right">
                    {DIALING_HOURS_CONFIG[timezone][LOF][DAY.SUNDAY]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}

      <Typography variant="h4" component="h1" className={classes.title}>
        Holidays by LOI
      </Typography>

      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>LOI</TableCell>
              <TableCell>Dial ON Holidays</TableCell>
              <TableCell>Dial OFF holidays</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(HOLIDAYS_BY_LOI).map((LOF) => (
              <TableRow key={LOF}>
                <TableCell component="th" scope="row">
                  {LOF}
                </TableCell>
                <TableCell>
                  {formatHolidays(HOLIDAYS_BY_LOI[LOF].ON)}
                  {HOLIDAYS_BY_LOI[LOF].rule && <div>({HOLIDAYS_BY_LOI[LOF].rule})</div>}
                </TableCell>
                <TableCell>{formatHolidays(HOLIDAYS_BY_LOI[LOF].OFF)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ul>
        {Object.keys(HOLIDAYS).map((h) => (
          <li key={HOLIDAYS[h].label}>
            {HOLIDAYS[h].label} - {HOLIDAYS[h].date}
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default DialingHours;
