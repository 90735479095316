import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function AlertDialog({ error, setError }) {
  const handleClose = () => {
    setError(null);
  };
  return (
    <div>
      <Dialog open={error !== null}>
        <DialogTitle id="alert-dialog-title">{error.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
