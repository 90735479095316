import React from "react";
import * as R from "ramda";
import * as moment from "moment-timezone";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Grid, Typography, Box, Paper, Collapse } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import DisplayInfoBox from "../DisplayInfoBox";
import ReactJson from "react-json-view";
import { camelCase, createWidgetAttributes, isObject } from "../../../lib/utils";

const sentenceCasedEvents = {
  route_initiated: "Route Initiated",
  inbound_task_created: "Inbound Task Created",
  inbound_task_rejected: "Inbound Task Rejected",
  route_connected: "Route Connected",
  route_ended: "Route Ended",
  route_failed: "Route Failed",
  route_canceled: "Route Canceled",
};

const timeZone = moment.tz.guess(true);
const createTimeLocalizer = (timeZone) => (epoch) => moment.unix(epoch).tz(timeZone);

const createTimeFormatter = (timeLocalizer) => (time) =>
  timeLocalizer(time).format("HH:mm:ss");
const timeLocalizer = createTimeLocalizer(timeZone);
const timeFormatter = createTimeFormatter(timeLocalizer);
const isRouteEnded = R.equals("route_ended");

const Event = (event) => {
  var displayTimelineConnector = true;
  const eventName = R.prop("name", event);
  const inboundTaskSidUrl = R.prop("taskSid", event)
    ? `/call-document/${R.prop("taskSid", event)}`
    : null;
  const eventNameDisplay = R.propOr(camelCase(eventName), eventName)(sentenceCasedEvents);

  if (isRouteEnded(eventName)) {
    displayTimelineConnector = false;
  }

  return (
    <Grid key={eventName}>
      <TimelineItem>
        {inboundTaskSidUrl ? (
          <TimelineOppositeContent>
            <a href={inboundTaskSidUrl}>{eventNameDisplay}</a>
          </TimelineOppositeContent>
        ) : (
          <TimelineOppositeContent>{eventNameDisplay}</TimelineOppositeContent>
        )}
        <TimelineSeparator>
          <TimelineDot />
          {displayTimelineConnector && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Typography>{timeFormatter(R.prop("epoch", event))}</Typography>
        </TimelineContent>
      </TimelineItem>
    </Grid>
  );
};

const KeyValue = (label, value) => {
  return (
    <Grid container alignContent="stretch" direction="row" wrap="nowrap" spacing={1}>
      <Grid item>
        <Typography variant="subtitle1">
          <Box fontWeight="fontWeightBold">{label}:</Box>
        </Typography>
      </Grid>
      <Grid item>
        {isObject(value) ? (
          <ReactJson src={value} displayDataTypes={false} collapsed={true} name={false} />
        ) : (
          <Typography variant="subtitle1">{value}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

const ProductAttributes = (product) => {
  return (
    <Grid item xs={6}>
      {KeyValue("Product ARN", R.prop("productArn", product))}
      {KeyValue("Product Type", R.prop("productType", product))}
      {KeyValue("Name", R.prop("name", product))}
      {KeyValue("Route UUID", R.prop("routeUuid", product))}
      {KeyValue("Call Id", R.prop("callerId", product))}
      {KeyValue("Phone Number", R.prop("phoneNumber", product))}
      {KeyValue("Buyer Id", R.prop("buyerId", product))}
      {KeyValue("Timeout", R.prop("timeout", product))}
      {KeyValue("Priority", R.prop("priority", product))}
      {KeyValue("Errors", R.propOr([], "errors", product))}
      {KeyValue("Routing Role", R.prop("routingRole", product))}
      {KeyValue("Transfer Type", R.prop("transferType", product))}
    </Grid>
  );
};

const CollapsedProduct = (product) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{R.prop("name", product)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>{ProductAttributes(product)}</Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const ProductWithEvent = (product, events) => {
  const productName = R.prop("name", product);
  const [expanded, setExpanded] = React.useState(productName);

  const handleChange = (product) => (event, newExpanded) => {
    setExpanded(newExpanded ? product : false);
  };

  return (
    <Accordion
      expanded={R.equals(expanded, productName)}
      onChange={handleChange(productName)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{R.prop("name", product)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {ProductAttributes(product)}
          <Grid item xs={12} md={6}>
            <Timeline>{R.map(Event, events)}</Timeline>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const Product = (product) => {
  const events = R.propOr([], "callRoutingEvents", product);
  return (
    <Grid item xs={12} key={product.buyerId}>
      {R.isEmpty(events) && CollapsedProduct(product)}
      {!R.isEmpty(events) && ProductWithEvent(product, events)}
    </Grid>
  );
};

const ExecutionPlan = ({ executionPlan }) => {
  const executionPlanType = R.prop("executionPlanType", executionPlan);
  const executionPlanErrors = R.propOr([], "executionPlanErrors", executionPlan);

  if (R.isNil(executionPlan) || R.isEmpty(executionPlan)) {
    return (
      <Typography variant="h6" align="center">
        Execution Plan info not found
      </Typography>
    );
  }

  return (
    <Grid container>
      <Grid item>
        <DisplayInfoBox
          data={createWidgetAttributes({
            executionPlanType,
            executionPlanErrors,
          })}
          timeLocalizer={timeLocalizer}
        />
      </Grid>

      <Grid item>
        <Typography variant="h6" gutterBottom>
          Buyers
        </Typography>
        <Grid container spacing={2}>
          {R.pipe(R.map(Product), R.values)(R.propOr([], "buyers", executionPlan))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExecutionPlan;
