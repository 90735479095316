import React from "react";
import * as R from "ramda";
import { Typography } from "@material-ui/core";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DisplayInfoBox from "../DisplayInfoBox";
import { createAlphabeticalWidgetAttributes } from "../../../lib/utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    border: "solid grey 2px",
    boxShadow: "5px 6px  #888888",
    width: 600,
  },
  recording: {},
}));

const IntroRecordings = ({ introRecordingInfo }) => {
  const classes = useStyles();
  if (R.isEmpty(introRecordingInfo)) {
    return null;
  }

  return (
    <Grid container>
      <Grid item>
        <Typography align="center" variant="h5">
          Recordings
        </Typography>
        <Paper className={classes.paper}>
          <Grid
            container
            alignContent="stretch"
            direction="row"
            wrap="nowrap"
            spacing={1}
          >
            <Grid item>
              <DisplayInfoBox
                data={createAlphabeticalWidgetAttributes(introRecordingInfo)}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default IntroRecordings;
