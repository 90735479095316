import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },

  dialog: {
    "& .MuiDialog-paper": {
      width: 400,
    },
  },

  tabs: {
    "& .tableWrapper": {
      border: "1px solid",
      borderColor: theme.palette.divider,
    },
  },

  addDialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    "& .role": {
      flex: 2,
    },
    "& .name": {
      flex: 2,
    },
    "& .code": {
      flex: 1,
    },
  },

  addButton: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

export default useStyles;
