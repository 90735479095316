import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { red, green } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(1, 0, 1, 2),
  },
}));

export default function DisplayStatus({ isBlackListed }) {
  const classes = useStyles();
  return (
    <Grid
      container
      key={`${isBlackListed}`}
      direction="row"
      alignItems="center"
      spacing={2}
      justifyContent="space-between"
    >
      <Typography>Current Status: </Typography>
      <Chip
        className={classes.chip}
        color={isBlackListed ? "secondary" : "primary"}
        style={{ backgroundColor: isBlackListed ? red[500] : green[500] }}
        label={isBlackListed ? "Blocked" : "Not Blocked"}
      />
    </Grid>
  );
}
