import React, { useCallback } from "react";
import { useQuery } from "react-query";
import {
  Container,
  Paper,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { TabPanel, TabContext, TabList } from "@material-ui/lab";
import useAxios from "../../../hooks/useAxios";
import { formatPhoneNumber, transformRole } from "../../../lib/utils";
import useStyles from "./styles";

const TransferNumbers = () => {
  const classes = useStyles();
  const axios = useAxios();
  const [currentTab, setCurrentTab] = React.useState("0");
  const query = useQuery(["transfers"], () => axios.post("get_transfer_numbers"));
  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const transfersResponse = query?.data?.data;
  const getTransfersArray = useCallback(() => {
    if (transfersResponse) {
      return Object.keys(transfersResponse).sort();
    }
    return null;
  }, [query.data]);

  return (
    <Container maxWidth="md" className={classes.container}>
      {query.status === "success" && (
        <Paper className={classes.tabsWrapper}>
          <TabContext value={currentTab}>
            <TabList
              onChange={handleChange}
              orientation="vertical"
              indicatorColor="primary"
            >
              {getTransfersArray().map((role, index) => (
                <Tab
                  key={role}
                  value={`${index}`}
                  label={transformRole(role)}
                  className={classes.tab}
                />
              ))}
            </TabList>

            {getTransfersArray().map((role, index) => (
              <TabPanel key={role} value={`${index}`} className={classes.tabPanel}>
                <TableContainer className={classes.tableWrapper}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Transfer</TableCell>
                        <TableCell align="center">Number</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(transfersResponse[role]).map((name) => (
                        <TableRow key={name}>
                          <TableCell>{name}</TableCell>
                          <TableCell align="center">
                            {formatPhoneNumber(transfersResponse[role][name])}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            ))}
          </TabContext>
        </Paper>
      )}
    </Container>
  );
};

export default TransferNumbers;
