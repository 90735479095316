import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  DialogTitle,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  DialogContent,
  DialogActions,
  Divider,
} from "@material-ui/core";
import { mapObjIndexedAndReturnValues } from "../../../lib/utils";
import { Controller, useForm, useWatch } from "react-hook-form";
import useStyles from "./styles";
import clsx from "clsx";

const validationSchema = yup.object({
  automated_loi_balancing: yup.object({
    insurance_lines: yup.lazy((obj) =>
      yup.object(
        R.map(
          () =>
            yup.object({
              target_utilization: yup
                .number("Number")
                .min(0, "Target Utilization must be at least 0")
                .max(99, "Target utilization must be less than 100")
                .nullable(true),

              base_allocation: yup
                .number("Number")
                .min(0, "Base Allocation must be at least 0")
                .max(0.99, "Base Allocation must be less than 1")
                .nullable(true),

              min_allocation: yup
                .number("Number")
                .min(0, "Min Allocation must be at least 0")
                .max(0.99, "Min Allocation must be less than 1")
                .nullable(true),
            }),
          obj,
        ),
      ),
    ),
  }),
});

const LOIBalancing = (props) => {
  const {
    outputAllocation,
    linesOfInsurance,
    handleSubmit,
    handleSimulate,
    simulatedAllocation,
    isSubmitting,
    isSimulating,
    refetch,
    loading,
  } = props;

  const classes = useStyles();
  const [simulateModalOpen, setSimulateModalOpen] = useState(false);

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      automated_loi_balancing: {
        insurance_lines: linesOfInsurance,
      },
    },
    mode: "onChange",
  });

  const handleCheckBoxChanged = (e, onChange, loiName) => {
    onChange(e);
    const targetUtilizationName = `automated_loi_balancing.insurance_lines.${loiName}.target_utilization`;
    const baseAllocationName = `automated_loi_balancing.insurance_lines.${loiName}.base_allocation`;
    const minAllocationName = `automated_loi_balancing.insurance_lines.${loiName}.min_allocation`;

    const target = form.watch(targetUtilizationName);
    const base = form.watch(baseAllocationName);
    const min = form.watch(minAllocationName);

    if (!e.target.checked) {
      if (!target) form.setValue(targetUtilizationName, 10);

      if (!min) form.setValue(minAllocationName, base);
    } else {
      if (!base) {
        form.setValue(baseAllocationName, min);
      }
    }
  };

  useEffect(() => {
    if (!!simulatedAllocation) {
      setSimulateModalOpen(true);
    }
  }, [simulatedAllocation]);

  useEffect(() => {
    if (isSubmitting) {
      setSimulateModalOpen(false);
    }
  }, [isSubmitting]);

  const renderAllocation = (allocation) => (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Line of Insurance</TableCell>
            <TableCell align="right">Allocation </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mapObjIndexedAndReturnValues(
            (value, lineOfInsurance) => (
              <TableRow key={lineOfInsurance}>
                <TableCell>{lineOfInsurance}</TableCell>
                <TableCell align="right">{value}</TableCell>
              </TableRow>
            ),
            allocation,
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const LOIs = form.watch("automated_loi_balancing.insurance_lines");

  return (
    <Box>
      <Box className={classes.datatable}>
        <Typography variant="subtitle2" gutterBottom>
          Min/Base Allocation and Target Utilization rate impact the calculation of load
          balancing for the Automated Line of Insurace balancing.
        </Typography>

        <Typography variant="caption">
          Overwrite determines how LOI Balancing will be calculated. With overwrite on for
          an LOI, allocation is calculated with just a base allocation value. With
          overwrite off, allocation uses a combination of target utilization and minimum
          allocation.
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Line of Insurance</TableCell>
                <TableCell>Overwrite</TableCell>
                <TableCell>Values</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(LOIs).map((loi) => (
                <TableRow key={loi}>
                  <TableCell>{loi}</TableCell>

                  <TableCell>
                    <Controller
                      control={form.control}
                      name={`automated_loi_balancing.insurance_lines.${loi}.overwrite`}
                      render={({ field, fieldState }) => (
                        <Checkbox
                          {...field}
                          size="small"
                          value={!!field.value}
                          checked={!!field.value}
                          color="primary"
                          onChange={(e) => handleCheckBoxChanged(e, field.onChange, loi)}
                        />
                      )}
                    />
                  </TableCell>

                  <TableCell>
                    {form.watch(
                      `automated_loi_balancing.insurance_lines.${loi}.overwrite`,
                    ) ? (
                      <Controller
                        control={form.control}
                        name={`automated_loi_balancing.insurance_lines.${loi}.base_allocation`}
                        render={({ field, fieldState }) => (
                          <TextField
                            label="Base allocation"
                            {...field}
                            size="small"
                            inputProps={{
                              precision: 2,
                              min: 0.0,
                              step: 0.01,
                            }}
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            className={classes.numberInput}
                            variant="outlined"
                          />
                        )}
                      />
                    ) : (
                      <Box display="flex">
                        <Controller
                          control={form.control}
                          name={`automated_loi_balancing.insurance_lines.${loi}.target_utilization`}
                          render={({ field, fieldState }) => (
                            <TextField
                              label="Target utilization"
                              {...field}
                              size="small"
                              inputProps={{
                                precision: 0,
                                min: 0,
                                step: 1,
                              }}
                              InputLabelProps={{ shrink: true }}
                              type="number"
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              className={classes.numberInput}
                              variant="outlined"
                            />
                          )}
                        />

                        <Controller
                          control={form.control}
                          name={`automated_loi_balancing.insurance_lines.${loi}.min_allocation`}
                          render={({ field, fieldState }) => (
                            <TextField
                              label="Min allocation"
                              {...field}
                              size="small"
                              inputProps={{
                                precision: 2,
                                min: 0.0,
                                step: 0.01,
                              }}
                              InputLabelProps={{ shrink: true }}
                              type="number"
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              className={clsx(classes.numberInput, "with-margin")}
                              variant="outlined"
                            />
                          )}
                        />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box className={classes.buttons}>
          <Button
            onClick={form.handleSubmit(handleSimulate)}
            variant="outlined"
            disabled={isSimulating}
          >
            Simulate
          </Button>

          <Button
            onClick={form.handleSubmit(handleSubmit)}
            disabled={!form.formState.isDirty || !form.formState.isValid || isSubmitting}
            variant="contained"
            color="primary"
          >
            Update
          </Button>
        </Box>
      </Box>

      <Divider className={classes.divider} />

      <Box className={classes.datatable}>
        <Typography variant="subtitle2" gutterBottom>
          Current Allocation
        </Typography>

        <Typography variant="caption">
          LOI Allocation displays the current allocation percentage calculated through the
          Automated LOI Balancer. This display is read-only.
        </Typography>

        {renderAllocation(outputAllocation)}

        <Box className={classes.buttons}>
          <Button onClick={refetch} disabled={loading}>
            Refetch
          </Button>
        </Box>
      </Box>

      <Dialog open={simulateModalOpen} onClose={() => setSimulateModalOpen(false)}>
        <DialogTitle id="simple-dialog-title">Simulation</DialogTitle>

        <DialogContent>
          <Typography variant="subtitle2" gutterBottom>
            This is the LOI allocation values that will be produced if you update the
            system with the current automated loi balancing values.
          </Typography>
          {renderAllocation(simulatedAllocation)}
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            onClick={() => setSimulateModalOpen(false)}
          >
            Close
          </Button>

          <Button
            onClick={form.handleSubmit(handleSubmit)}
            disabled={!form.formState.isValid || isSubmitting}
          >
            Update Now
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LOIBalancing;
