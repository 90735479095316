import React from "react";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(1, 0, 1, 2),
  },
}));

export default function DisplayInfo({ phoneNumberInfo, isBlackListed }) {
  const classes = useStyles();
  const convertEpochToLocalTime = (epoch) => moment.unix(epoch).toString();

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{isBlackListed ? "Blocked by" : "Unblocked by"}</Typography>
        </Grid>
        <Grid>
          <Chip label={phoneNumberInfo.statusSetBy} className={classes.chip} />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography>{isBlackListed ? "Blocked on" : "Unblocked on"}</Typography>
        </Grid>
        <Grid>
          <Chip
            label={convertEpochToLocalTime(phoneNumberInfo.statusReasonTime)}
            className={classes.chip}
          />
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item />
        <Typography>Reason</Typography>
        <Grid>
          <Typography>{phoneNumberInfo.statusReason}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}
