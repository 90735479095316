import axios from "axios";
import auth0Client from "../auth/Auth";

const GET_CONSENT_RECORDING_UPLOAD_URL = "get_consent_recording_upload_url";
const ADD_CONSENT_AUDIO_MAPPING = "add_consent_audio_mapping";
const GET_GUIDE_RECORDINGS = "get_guide_recordings";

export const getConsentAudioUploadUrl = (name) =>
  axios.post(
    `${process.env.REACT_APP_BACKEND}/${GET_CONSENT_RECORDING_UPLOAD_URL}`,
    {
      file_name: name,
    },
    {
      headers: {
        Authorization: `Bearer ${auth0Client.getIdToken()}`,
      },
    },
  );

export const addConsentAudioMapping = (name, type) =>
  axios.post(
    `${process.env.REACT_APP_BACKEND}/${ADD_CONSENT_AUDIO_MAPPING}`,
    {
      file_name: name,
      file_type: type,
    },
    {
      headers: {
        Authorization: `Bearer ${auth0Client.getIdToken()}`,
      },
    },
  );

export const uploadConsentRecording = (url, file) =>
  axios.put(url, file, {
    headers: {
      "Content-Type": "audio/wav",
    },
  });

export const getIntroRecordingsForUser = (user_id) =>
  axios.post(
    `${process.env.REACT_APP_BACKEND}/${GET_GUIDE_RECORDINGS}`,
    {
      user_id: user_id,
    },
    {
      headers: {
        Authorization: `Bearer ${auth0Client.getIdToken()}`,
      },
    },
  );

// TODO: Move all backend calls here
