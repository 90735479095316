import React, { useEffect } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as R from "ramda";
import ReactJson from "react-json-view";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { checkNumberValidity } from "../../lib/PhoneNumberMask";
import { isBoolean, isObject, formatPhoneNumber } from "../../lib/utils";

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: "#e0e0e",
    marginBottom: 20,
  },
}));
const isNotNil = R.complement(R.isNil);

const PlayAudio = ({ src }) => {
  const ref = React.useRef(null);
  useEffect(() => {
    // To refresh the audio incase of 2nd search
    ref.current.pause();
    ref.current.load();
  }, [src]);

  return (
    <audio ref={ref} style={{ height: 20, width: 400 }} controls>
      <source src={src} />
    </audio>
  );
};

const DisplayAttribute = ({ field, value, type, link }) => {
  return (
    <Grid container alignContent="stretch" direction="row" wrap="nowrap" spacing={1}>
      <Grid item>
        <Typography component="div" variant="subtitle1">
          <Box fontWeight="fontWeightBold">{field}:</Box>
        </Typography>
      </Grid>
      <Grid item>{getDisplayValue(type, value, link)}</Grid>
    </Grid>
  );
};

const getDisplayValue = (type, value, link) => {
  if (isBoolean(value)) {
    return <Typography variant="subtitle1">{value ? "true" : "false"}</Typography>;
  } else if (R.equals("audio", type) && isNotNil(link)) {
    return <PlayAudio src={link} />;
  } else if (R.equals("newWindowLink", type) && isNotNil(link)) {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        {value}
      </a>
    );
  } else if (R.equals("link", type) && isNotNil(link)) {
    return <a href={link}>{value}</a>;
  } else if (isNotNil(value) && isObject(value)) {
    return (
      <Typography variant="subtitle1">
        <ReactJson src={value} displayDataTypes={false} collapsed={true} name={false} />
      </Typography>
    );
  } else if (typeof value === "string" && isPossiblePhoneNumber(value)) {
    // NOTE some numbers are with the "+1" and some others not. Thats why we need this double check
    return <Typography variant="subtitle1">{formatPhoneNumber(value)}</Typography>;
  } else if (typeof value === "string" && checkNumberValidity(value)) {
    return <Typography variant="subtitle1">{formatPhoneNumber(`+1${value}`)}</Typography>;
  } else if (isNotNil(value)) {
    return <Typography variant="subtitle1">{value}</Typography>;
  } else {
    return <Typography variant="subtitle1">-</Typography>;
  }
};

const DisplayCallInfo = ({ data, title, attributeClass }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Typography variant="h5">{title}</Typography>
      <Grid
        container
        className={attributeClass ? attributeClass : classes.infoContainer}
        spacing={2}
        direction="column"
      >
        {data.map((attr) => (
          <DisplayAttribute key={`${attr}${attr.field}`} {...attr} />
        ))}
      </Grid>
    </Grid>
  );
};

export default DisplayCallInfo;
