import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  numberInput: {
    width: 130,
    "&.with-margin": {
      marginLeft: theme.spacing(2),
    },
  },

  divider: {
    margin: theme.spacing(4, 0),
  },

  datatable: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },

  buttons: {
    justifyContent: "flex-end",
    display: "flex",
    gap: theme.spacing(2),
  },
}));

export default useStyles;
