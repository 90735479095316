import React from "react";
import * as R from "ramda";
import { Grid, Typography, Link } from "@material-ui/core";
import { isPossiblePhoneNumber, formatPhoneNumber } from "react-phone-number-input";
import Chart from "./Chart";
import { WarningTypography } from "../../../lib/typographyUtils";
import { twilioErrorExplanations } from "./twilioErrorExplanations";

const maxEpoch = (leaveOrJoin) =>
  R.pipe(R.map(R.prop(leaveOrJoin)), R.reject(R.isNil), R.reduce(R.pipe(R.max), 0));

const getParticipantInfo = R.pipe(R.prop("participantsInfo"), R.values);

const determineEndTime = R.pipe(
  getParticipantInfo,
  R.converge(R.max, [
    maxEpoch("leaveTimeEpoch"),
    R.pipe(maxEpoch("joinTimeEpoch"), R.add(20)), // 20 Seconds in case everything is 0
  ]),
);

const generateParticipantGraphInfo = (conference, timeFormatter) => (x) => {
  const startTime = conference.startTimeEpoch;
  let endTime = conference.endTimeEpoch;

  if (R.isNil(endTime)) {
    endTime = determineEndTime(conference);
  }

  const duration = endTime - startTime;
  return {
    start: (x.joinTimeEpoch - startTime) / duration,
    width: R.max(
      (R.defaultTo(endTime, x.leaveTimeEpoch) - x.joinTimeEpoch) / duration,
      0.01, // Minimum size of a bar
    ),
    startTime: timeFormatter(x.joinTimeEpoch),
    endTime: timeFormatter(x.leaveTimeEpoch),
    color: x.leaveTimeEpoch ? "#0a522e" : "#960909",
    legend: x.leaveTimeEpoch ? "Left Call" : "Didn't Leave Call",
    name: isPossiblePhoneNumber(x.name) ? `+1 ${formatPhoneNumber(x.name)}` : x.name,
    routingId: R.prop("routingId", x),
  };
};

const getErrorReason = R.prop("errorReason");
const getErrorExplanation = (conference) => {
  return R.prop(getErrorReason(conference), twilioErrorExplanations);
};
const getErrorDescription = (conference) => {
  return R.prop("description", getErrorExplanation(conference));
};
const getErrorLink = (conference) => {
  return R.prop("link", getErrorExplanation(conference));
};

const generateConferenceInfo = (conference, timeFormatter) => {
  return {
    start: 0,
    width: 1,
    startTime: timeFormatter(conference.startTimeEpoch),
    endTime: timeFormatter(conference.endTimeEpoch),
    color: "#c9b410",
    legend: "Total Call Duration",
    name: "conference",
  };
};

const conferenceObjectToGraphData = (conference, timeFormatter) => {
  const nilHandleTimeFormatter = R.ifElse(R.isNil, R.always("N/A"), timeFormatter);

  return R.pipe(
    getParticipantInfo,
    R.sortWith([
      R.ascend(R.prop("joinTimeEpoch")),
      R.ascend(R.prop("leaveSequenceNumber")),
    ]),
    R.map(generateParticipantGraphInfo(conference, nilHandleTimeFormatter)),
    R.prepend(generateConferenceInfo(conference, nilHandleTimeFormatter)),
  )(conference);
};

const ConferenceTimeline = ({ conference, timeFormatter }) => {
  if (R.isNil(conference)) {
    return (
      <Typography variant="h6" align="center">
        Conference timeline info not found
      </Typography>
    );
  }

  return (
    <Grid container direction="column">
      <Chart data={conferenceObjectToGraphData(conference, timeFormatter)} />
      {getErrorReason(conference) ? (
        <React.Fragment>
          <WarningTypography variant="subtitle1" align="left">
            {`Error: call failed because the agent received a ${getErrorReason(
              conference,
            )} error from Twilio`}
          </WarningTypography>
          <Typography variant="subtitle2" align="left">
            {`${getErrorDescription(
              conference,
            )} you can find out more about how to resolve the issue`}
            <Link href={getErrorLink(conference)} target="_blank" rel="noreferrer">
              {" "}
              here
            </Link>
            .
          </Typography>
        </React.Fragment>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default ConferenceTimeline;
