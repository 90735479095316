import React, { useEffect, useState } from "react";
import * as R from "ramda";
import * as moment from "moment-timezone";
import DisplayInfoBox from "../DisplayInfoBox";
import Typography from "@material-ui/core/Typography";
import {
  Container,
  Box,
  Avatar,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  LinearProgress,
  Tab,
} from "@material-ui/core";
import {
  Alert,
  TabList,
  TabPanel,
  TabContext,
  Skeleton,
  AlertTitle,
} from "@material-ui/lab";
import {
  getInitials,
  createAlphabeticalWidgetAttributes,
  createTimeLocalizer,
} from "../../../lib/utils";
import { Button } from "@material-ui/core";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import camelcaseKey from "camelcase-keys";
import ActiveTasks from "./active_tasks";
import useAxios from "../../../hooks/useAxios";
import SearchBox from "../../../lib/SearchBox";
import useStyles from "./styles";
import IntroRecordings from "./intro_recordings";

const timeZone = moment.tz.guess(true);
const timeLocalizer = createTimeLocalizer(timeZone);

const AgentInfo = () => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const axios = useAxios();
  const { agentId } = params;
  const query = useQuery(
    ["agent", agentId],
    () => axios.post("get_agent_attributes", { user_id: agentId }),
    { enabled: !!agentId },
  );
  const reprovisionMutation = useMutation((email) =>
    axios.post("reprovision_agent_phone_", { email }),
  );
  const [showAlert, setShowAlert] = useState(false);
  const [currentTab, setCurrentTab] = React.useState("0");

  useEffect(() => {
    if (reprovisionMutation.isSuccess) {
      setShowAlert(false);
    }
  }, [reprovisionMutation.status]);

  useEffect(() => {
    reprovisionMutation.reset();
  }, [showAlert]);

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const agentInfo = camelcaseKey(R.path(["data", "data"], query), { deep: true });
  const tabs = agentInfo
    ? [
        {
          label: "Info",
          content: (
            <DisplayInfoBox
              data={createAlphabeticalWidgetAttributes(
                R.dissoc("introRecordingInfo", R.dissoc("activeTasks", agentInfo)),
              )}
              timeLocalizer={timeLocalizer}
            />
          ),
        },
        {
          label: "Active Tasks",
          content: <ActiveTasks activeTasks={agentInfo?.activeTasks} />,
        },
      ]
    : [];

  if (R.not(R.isNil(agentInfo?.introRecordingInfo))) {
    tabs.push({
      label: "Intro Recordings",
      content: <IntroRecordings introRecordingInfo={agentInfo?.introRecordingInfo} />,
    });
  }

  return (
    <Container maxWidth="md" className={classes.root}>
      <SearchBox
        label="Agent Id"
        value={agentId}
        onSearch={(value) => history.push(`/agent-info/${value}`)}
      />

      <Box className={classes.header}>
        <Box className={classes.avatar}>
          {query.isLoading ? (
            <Skeleton animation="wave" variant="circle" width={40} height={40} />
          ) : query.isSuccess ? (
            <Tooltip title={agentInfo.name}>
              <Avatar
                src={agentInfo.avatar}
                sx={{
                  height: 64,
                  mr: 2,
                  width: 64,
                }}
              >
                {getInitials(agentInfo.name)}
              </Avatar>
            </Tooltip>
          ) : null}
        </Box>
        <Box className={classes.details}>
          {query.isLoading ? (
            <>
              <Skeleton animation="wave" height={25} width={250} />
              <Skeleton animation="wave" height={30} width={250} />
            </>
          ) : query.isSuccess ? (
            <>
              <Typography variant="h6">{agentInfo.email}</Typography>
              <Typography variant="overline" component="p">
                {agentInfo.name}
              </Typography>
            </>
          ) : null}
        </Box>

        {agentInfo?.phoneNumber && (
          <Box className={classes.actions}>
            <Tooltip title="Reprovision Agent Phone Number">
              <Button onClick={() => setShowAlert(true)} variant="outlined">
                Reprovision Phone
              </Button>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Dialog open={showAlert}>
        <DialogTitle>Reprovision Agent Phone Number?</DialogTitle>
        <DialogContent>
          {reprovisionMutation.isLoading ? (
            <LinearProgress />
          ) : reprovisionMutation.error ? (
            <Alert severity="error" className={classes.error}>
              <Typography>
                {reprovisionMutation.error.code}: {reprovisionMutation.error.message}
              </Typography>
            </Alert>
          ) : (
            "The agent will lose the existing phone number and will be assigned a new one. Would you still like to proceed ?"
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAlert(false)} autoFocus>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => reprovisionMutation.mutate(agentInfo.email)}
            disabled={reprovisionMutation.isLoading}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      {query.isLoading ? (
        <Skeleton animation="wave" height={450} variant="rect" />
      ) : query.isSuccess ? (
        <TabContext value={currentTab}>
          <TabList onChange={handleChange} indicatorColor="primary">
            {tabs.map((tab, index) => (
              <Tab
                key={tab.label}
                className={classes.tab}
                value={`${index}`}
                label={tab.label}
              />
            ))}
          </TabList>

          {tabs.map((tab, index) => (
            <TabPanel key={tab.label} value={`${index}`} className={classes.tabPanel}>
              {tab.content}
            </TabPanel>
          ))}
        </TabContext>
      ) : null}

      {query.isError && (
        <Alert severity="error">
          <AlertTitle>Failed to load agent info.</AlertTitle>
          {query?.error?.message}
        </Alert>
      )}
    </Container>
  );
};
export default AgentInfo;
