import React from "react";
import { Checkbox, Button, Typography, Box } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import useStyles from "./styles";

const PowerDialing = (props) => {
  const { value, handleSubmit, isSubmitting } = props;
  const classes = useStyles();

  const form = useForm({
    defaultValues: {
      power_dialing: {
        config_value: value,
      },
    },
  });

  return (
    <Box className={classes.datatable}>
      <Typography variant="subtitle2">
        Power Dialing Switch turns on and off the power dialing system.
      </Typography>

      <Box>
        <Controller
          control={form.control}
          name="power_dialing.config_value"
          render={({ field, fieldState }) => (
            <Checkbox {...field} checked={field.value} color="primary" />
          )}
        />
      </Box>

      <Box>
        <Button
          onClick={form.handleSubmit(handleSubmit)}
          disabled={isSubmitting}
          variant="contained"
          color="primary"
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default PowerDialing;
