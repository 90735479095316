import React from "react";
import * as R from "ramda";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import DisplayInfo from "./DisplayInfo";
import DisplayStatus from "./DisplayStatus";
import SearchBox from "./SearchBox";
import ChangeStatus from "./ChangeStatus";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "80vh",
  },
  paper: {
    padding: theme.spacing(1, 5, 1, 5),
    marginBottom: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(1, 0, 1, 2),
  },
  phoneNumberInfo: {
    width: "50vw",
    minWidth: 600,
    maxWidth: 800,
  },
}));

export default function BlackListing() {
  const [phoneNumberInfo, setPhoneNumberInfo] = React.useState("");
  const [changingStatus, setChangingStatus] = React.useState(false);
  // Reset changing state when phone number is changed
  React.useEffect(() => setChangingStatus(false), [phoneNumberInfo]);
  const classes = useStyles();

  const isBlackListed = R.propOr(false, "isBlacklisted", phoneNumberInfo);

  const isListed = R.prop("isListed", phoneNumberInfo);
  return (
    <Grid
      spacing={4}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <Typography align="center" variant="h5">
          Search Phone Number Status
        </Typography>
      </Grid>
      <Grid item>
        <SearchBox setPhoneNumberInfo={setPhoneNumberInfo} />
      </Grid>
      {phoneNumberInfo &&
        (changingStatus ? (
          <Grid item>
            <ChangeStatus
              setChangingStatus={setChangingStatus}
              phoneNumberInfo={phoneNumberInfo}
              isBlackListed={isBlackListed}
              setPhoneNumberInfo={setPhoneNumberInfo}
            />
          </Grid>
        ) : (
          <Grid item>
            <Grid
              className={classes.phoneNumberInfo}
              container
              direction="column"
              alignItems="stretch"
            >
              <Paper className={classes.paper}>
                <DisplayStatus isBlackListed={isBlackListed} />
              </Paper>
              {isListed && (
                <Paper className={classes.paper}>
                  <DisplayInfo
                    phoneNumberInfo={phoneNumberInfo}
                    isBlackListed={isBlackListed}
                  />
                </Paper>
              )}
              <Grid container justifyContent="center">
                <Button
                  onClick={() => setChangingStatus(true)}
                  label="BlockedList"
                  direction="row"
                  color="primary"
                >
                  {isBlackListed ? "Unblock Number" : "Block Number"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
}
