import React from "react";
import * as R from "ramda";
import axios from "axios";
import auth0Client from "./../../../auth/Auth";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ErrorDialog from "./../../../lib/ErrorDialog";
import camelcaseKey from "camelcase-keys";

const useStyles = makeStyles((theme) => ({
  textBox: {
    width: "50vw",
    minWidth: 400,
    maxWidth: 600,
  },
}));

export default function ChangeStatus({
  phoneNumberInfo,
  isBlackListed,
  setPhoneNumberInfo,
  setChangingStatus,
}) {
  const classes = useStyles();
  const [reason, setReason] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleChange = (e) => {
    setReason(e.target.value);
  };

  const handleSubmit = async (e) => {
    setSubmitting(true);
    e.preventDefault();
    try {
      const res = await axios.post(
        process.env.REACT_APP_BACKEND + "post_phone_number_blacklisting_info",
        {
          phone_number: phoneNumberInfo.phoneNumber,
          status_reason: reason,
          is_blacklisted: !isBlackListed,
        },
        {
          headers: { Authorization: `Bearer ${auth0Client.getIdToken()}` },
        },
      );
      setSubmitting(false);
      setPhoneNumberInfo(camelcaseKey(res.data));
    } catch (err) {
      setSubmitting(false);
      setError({
        title: "Couldn't connect to server",
        message: "Please check your internet connection.",
      });
    }
  };
  const handleCancel = (e) => {
    setChangingStatus(false);
    e.preventDefault();
  };
  return (
    <form onSubmit={handleSubmit}>
      {error && <ErrorDialog error={error} setError={setError} />}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <TextField
            className={classes.textBox}
            label={
              isBlackListed
                ? "Reason for removing from blocked list"
                : "Reason for adding to blocked list"
            }
            multiline
            value={reason}
            onChange={handleChange}
            variant="outlined"
            rows={10}
          />
        </Grid>
        <Grid item>
          <Button
            type="submit"
            disabled={submitting || R.isEmpty(reason)}
            color="primary"
          >
            {submitting ? "Changing Status..." : "Change Status"}
          </Button>
          <Button disabled={submitting} onClick={handleCancel} color="primary">
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
