import React, { useCallback } from "react";
import { useQuery } from "react-query";
import {
  Container,
  Paper,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { TabPanel, TabContext, TabList } from "@material-ui/lab";
import useAxios from "../../../hooks/useAxios";
import {transformDisposition} from "../../../lib/utils";
import useStyles from "./styles";

const InboundVirtualAssistant = () => {
  const classes = useStyles();
  const axios = useAxios();
  const [currentTab, setCurrentTab] = React.useState("0");
  const query = useQuery(["inbound_virtual_assistant_calls"], () => axios.post("get_inbound_virtual_assistant_calls"));
  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const callsResponse = query?.data?.data;
  const getCallsArray = useCallback(() => {
    if (callsResponse) {
      return Object.keys(callsResponse).sort();
    }
    return null;
  }, [query.data]);

  return (
    <Container maxWidth="md" className={classes.container}>
      {query.status === "success" && (
        <Paper className={classes.tabsWrapper}>
          <TabContext value={currentTab}>
            <TabList
              onChange={handleChange}
              orientation="vertical"
              indicatorColor="primary"
            >
              {getCallsArray().map((disposition, index) => (
                <Tab
                  key={disposition}
                  value={`${index}`}
                  label={transformDisposition(disposition)}
                  className={classes.tab}
                />
              ))}
            </TabList>

            {getCallsArray().map((disposition, index) => (
              <TabPanel key={disposition} value={`${index}`} className={classes.tabPanel}>
                <TableContainer className={classes.tableWrapper}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell align="center">Call</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(callsResponse[disposition]).map((name) => (
                        <TableRow key={name}>
                          <TableCell>{name.slice(5)}</TableCell>
                          <TableCell align="center">
                            <a href={callsResponse[disposition][name]} target="_blank" rel="noopener noreferrer">
                              {callsResponse[disposition][name].slice(-39, -1)}
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            ))}
          </TabContext>
        </Paper>
      )}
    </Container>
  );
};

export default InboundVirtualAssistant;
