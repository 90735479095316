import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as R from "ramda";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ErrorDialog from "../../../lib/ErrorDialog";
import Typography from "@material-ui/core/Typography";
import {
  addConsentAudioMapping,
  getConsentAudioUploadUrl,
  uploadConsentRecording,
} from "../../../lib/DeltaAdminService";
import { isSuccesfullApiCall } from "../../../lib/utils";

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: 2,
    minHeight: 40,
  },
  recordingTypeStyle: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  uploadRecordingLabel: {
    fontSize: 16,
    fontWeight: 350,
    paddingRight: 20,
  },
  button: {
    paddingTop: 40,
  },
}));

const isWavFile = R.pipe(R.prop("name"), R.endsWith(".wav"));
const RECORDING_TYPES = ["lumico_question", "voice_signature", "compliance_recording"];

const Option = (option) => (
  <MenuItem key={option} value={option}>
    {option}
  </MenuItem>
);

const RecordingUpload = (props) => {
  const [recording, setRecording] = React.useState("");
  const [type, setType] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState();

  const classes = useStyles();

  const handleUpload = (event) => {
    setRecording(R.last(R.path(["target", "files"], event)));
  };

  const resetFile = (event) => {
    event.target.value = null;
  };

  const setRecordingType = (event) => {
    setType(event.target.value);
  };

  const displayErrorMessage = (status) => {
    var message =
      "Cannot upload compliance recording. Please try again. Contact support if the issue persists";
    if (R.equals(400, status)) {
      message = "Recording already exists";
    }
    setError({
      title: "Request Failed",
      message,
    });
  };

  const uploadRecording = async () => {
    const recordingData = new FormData();

    recordingData.append("file_name", R.prop("name", recording));
    recordingData.append("file_type", type);
    recordingData.append("data", recording);

    try {
      const response = await getConsentAudioUploadUrl(R.prop("name", recording));
      if (isSuccesfullApiCall(response)) {
        const uploadResponse = await uploadConsentRecording(
          R.path(["data", "url"], response),
          recording,
        );
        if (isSuccesfullApiCall(uploadResponse)) {
          const addDeltaMappingResponse = await addConsentAudioMapping(
            R.prop("name", recording),
            type,
          );
          if (isSuccesfullApiCall(addDeltaMappingResponse)) {
            setSubmitting(false);
            setType("");
            return;
          }
        }
      }
      displayErrorMessage();
    } catch (error) {
      setSubmitting(false);
      displayErrorMessage(R.path(["response", "status"], error));
    }
  };

  const submit = (e) => {
    if (!recording || !type || !isWavFile(recording)) {
      setError({
        title: "Invalid request",
        message: "Please upload a valid recording(.wav) and recording type",
      });
      return;
    }
    setSubmitting(true);
    uploadRecording();
  };

  return (
    <Grid>
      {error && <ErrorDialog error={error} setError={setError} />}
      <Grid
        container
        className={classes.container}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={0}
      >
        <Grid item xs={12}>
          <Typography align="center" variant="h5">
            Upload Compliance Recording
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.recordingTypeStyle}>
          <TextField
            select
            label="Type"
            value={type}
            fullWidth
            required
            onChange={setRecordingType}
          >
            {R.map(Option, RECORDING_TYPES)}
          </TextField>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={3}>
          <label className={classes.uploadRecordingLabel}>
            <b>Recording: </b>
          </label>
          <input type="file" onChange={handleUpload} onClick={resetFile} />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={1} className={classes.button}>
          <Button
            onClick={submit}
            color="primary"
            variant="contained"
            disabled={submitting}
          >
            {submitting ? "Submitting" : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RecordingUpload;
