import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import auth0Client from "./../../../auth/Auth";
import ErrorDialog from "./../../../lib/ErrorDialog";
import SearchButton from "./../../../lib/SearchButton";
import camelcaseKey from "camelcase-keys";
import axios from "axios";
import {
  PhoneNumberMask,
  checkNumberValidity,
  stripPhoneNumber,
} from "../../../lib/PhoneNumberMask";

const useStyles = makeStyles((theme) => ({
  container: {
    border: "grey 2px solid",
    borderRadius: 5,
    boxShadow: 2,
    minHeight: 40,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRight: "solid grey 2px",
  },
}));

export default function SearchBox({ setPhoneNumberInfo }) {
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState();

  const classes = useStyles();

  const handleChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleSubmit = async (e) => {
    setSubmitting(true);
    e.preventDefault();
    try {
      const res = await axios.post(
        process.env.REACT_APP_BACKEND + "get_phone_number_blacklisting_info",
        { phone_number: `+1${stripPhoneNumber(phoneNumber)}` },
        {
          headers: { Authorization: `Bearer ${auth0Client.getIdToken()}` },
        },
      );
      setPhoneNumberInfo(camelcaseKey(res.data));
    } catch (err) {
      setError({
        title: "Couldn't connect to server",
        message: "Please check your internet connection.",
      });
    }
    setSubmitting(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      {error && <ErrorDialog error={error} setError={setError} />}
      <Grid
        container
        className={classes.container}
        direction="row"
        alignItems="center"
        spacing={0}
      >
        <FormControl className={classes.textField}>
          <Input
            type="tel"
            disableUnderline
            autoComplete="off"
            onChange={handleChange}
            value={phoneNumber}
            id="formatted-text-mask-input"
            inputComponent={PhoneNumberMask}
          />
        </FormControl>
        <SearchButton
          searching={submitting}
          disabled={!checkNumberValidity(phoneNumber) || submitting}
        />
      </Grid>
    </form>
  );
}
