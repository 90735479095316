import React from "react";
import { pipe, mapObjIndexed, values } from "ramda";
import { makeStyles, TextField, MenuItem } from "@material-ui/core";
import * as moment from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 180,
  },
}));

const US_TIMEZONES = {
  HST: "Pacific/Honolulu",
  AKST: "America/Anchorage",
  PST: "America/Los_Angeles",
  MST: "America/Creston",
  CST: "America/Regina",
  EST: "America/New_York",
  "My Timezone": moment.tz.guess(),
};

const TimezonePicker = ({ timeZone, setTimeZone }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    setTimeZone(event.target.value);
  };

  return (
    <TextField
      className={classes.root}
      select
      label="Change Timezone"
      value={timeZone}
      onChange={handleChange}
      variant="outlined"
      size="small"
    >
      {pipe(
        mapObjIndexed((tzFull, tzAbbr) => (
          <MenuItem key={tzFull} value={tzFull}>
            {tzAbbr}
          </MenuItem>
        )),
        values,
      )(US_TIMEZONES)}
    </TextField>
  );
};
export default TimezonePicker;
