import React from "react";
import loading from "./loading.svg";

export default function Loading() {
  const style = {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
  };

  return (
    <div style={style}>
      <img src={loading} alt="loading" width="120" />
    </div>
  );
}
