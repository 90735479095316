import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "react-toastify/dist/ReactToastify.css";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import * as R from "ramda";
import { withRouter, Link, Route, Redirect, Switch } from "react-router-dom";
import routeAuthorizer from "../auth/RouteAuthorizer";
import BlackListing from "./Main/blacklisting";
import Whitelist from "./Main/whitelist";
import CallDocument from "./Main/call_doc";
import RecordingUpload from "./Main/consent_recording/RecordingUpload";
import CallRoutingDocument from "./Main/call_routing_doc";
import AgentInfo from "./Main/agent_info";
import PhoneInfo from "./Main/phone_info";
import Dispositions from "./Main/dispositions";
import TransferNumbers from "./Main/transfer_numbers";
import InboundVirtualAssistant from "./Main/inbound_virtual_assistant";
import DialingConfiguration from "./Main/dialing_configuration";
import DeltaConfiguration from "./Main/delta_configuration";
import OutboundAutoExperiments from "./Main/outbound_auto_experiments";
import DialingHours from "./Main/dialing_hours";
import PageNotFound from "./Main/PageNotFound";
import DialerVersions from "./Main/dialer_versions/DialerVersions";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  drawer: {
    whiteSpace: "nowrap",
    "& .MuiPaper-root": {
      width: drawerWidth,
      backgroundColor: theme.palette.primary.main,
    },
  },

  main: {
    marginLeft: drawerWidth,
    padding: theme.spacing(2),
  },

  logo: {
    borderRadius: 0,
    textTransform: "none",
    color: theme.palette.primary.contrastText,
  },

  link: {
    color: theme.palette.primary.contrastText,
    "&:hover, &.Mui-selected": {
      backgroundColor: `${theme.palette.primary.dark}!important`,
    },
  },

  logout: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.secondary.main,
    margin: theme.spacing(2),
    "&:hover": {
      background: theme.palette.secondary.dark,
    },
  },
}));

function Navigation(props) {
  const ROUTES = [
    {
      label: "Block",
      path: "/blocked-listing",
      exact: true,
      component: <BlackListing />,
      hidden: !routeAuthorizer.canBlackListNumbers(),
    },

    {
      path: "/black-listing",
      exact: true,
      component: <Redirect to="/blocked-listing" />,
    },

    {
      label: "Whitelist",
      path: "/whitelist",
      exact: true,
      component: <Whitelist />,
      hidden: !routeAuthorizer.canBlackListNumbers(),
    },

    {
      label: "Calls",
      link: "/call-document",
      path: "/call-document/:callId?",
      component: <CallDocument />,
    },

    {
      label: "Routes",
      link: "/call-routing-document",
      path: "/call-routing-document/:callRoutingId?",
      component: <CallRoutingDocument />,
    },

    {
      label: "Agent",
      link: "/agent-info",
      path: "/agent-info/:agentId?",
      component: <AgentInfo />,
    },

    {
      label: "Phone",
      link: "/phone-info",
      path: "/phone-info/:phoneNumber?",
      component: <PhoneInfo />,
    },

    {
      label: "Dispositions",
      path: "/dispositions",
      exact: true,
      component: <Dispositions />,
    },

    {
      label: "Xfers",
      path: "/transfer-numbers",
      exact: true,
      component: <TransferNumbers />,
      hidden: !routeAuthorizer.canViewXfers(),
    },

    {
      label: "Inbound Virtual Assistant",
      path: "/inbound-virtual-assistant",
      exact: true,
      component: <InboundVirtualAssistant />,
      hidden: !routeAuthorizer.canViewXfers(),
    },

    {
      label: "Record",
      path: "/consent-recording",
      exact: true,
      component: <RecordingUpload />,
    },

    {
      label: "Outbound Auto",
      path: "/outbound-auto-experiments/:queuingId?",
      link: "/outbound-auto-experiments",
      component: <OutboundAutoExperiments />,
      hidden: !routeAuthorizer.canViewAutoExperiments(),
    },

    {
      label: "Dialing Hours",
      path: "/dialing-hours",
      exact: true,
      component: <DialingHours />,
    },

    {
      label: "Dialing configuration",
      path: "/dialing-configuration",
      exact: true,
      component: <DialingConfiguration />,
      hidden: !routeAuthorizer.canChangeDialingConfig(),
    },
    {
      label: "Delta configuration",
      path: "/delta-configuration",
      exact: true,
      component: <DeltaConfiguration />,
      hidden: !routeAuthorizer.canChangeDeltaConfig(),
    },

    {
      label: "Dialer Version Management",
      path: "/dialer-versions",
      exact: true,
      component: <DialerVersions />,
      hidden: !routeAuthorizer.canUpdateDynamoFilters(),
    },
  ];

  if (props.location.pathname === "/") {
    props.history.replace("/call-document");
  }
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer variant="permanent" className={classes.drawer} open>
        <Button component="span" className={classes.logo} size="large">
          Delta Admin
        </Button>
        <Divider />
        <List component="div">
          {ROUTES.filter((item) => !item.hidden && !!item.label).map((route) => (
            <ListItem
              key={route.path}
              to={route.link ? route.link : route.path}
              className={classes.link}
              button
              selected={
                R.includes(route.link, props.location.pathname) ||
                R.includes(route.path, props.location.pathname)
              }
              component={Link}
            >
              <ListItemText primary={route.label} />
            </ListItem>
          ))}
        </List>
        <Divider />

        <Button to="/signout" className={classes.logout} component={Link}>
          Log Out
        </Button>
      </Drawer>
      <main className={classes.main}>
        <Switch>
          {ROUTES.filter((item) => !item.hidden).map((route) => (
            <Route key={route.path} exact={!!route.exact} path={route.path}>
              {route.component}
            </Route>
          ))}

          <Route component={PageNotFound} />
        </Switch>
      </main>
    </div>
  );
}

export default withRouter(Navigation);
