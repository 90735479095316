import React, { forwardRef, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import useStyles from "./styles";
import clsx from "clsx";

const CustomPhoneInput = forwardRef(({ value, onChange, ...others }, ref) => (
  <InputBase
    inputRef={ref}
    value={value}
    onChange={onChange}
    {...others}
    inputProps={{ maxLength: 15 }}
  />
));
export default function SearchBox(props) {
  const {
    className,
    type = "text",
    label,
    value: valueProp = "",
    onSearch,
    secondaryAction = null,
  } = props;
  const classes = useStyles();
  const [value, setValue] = useState(valueProp);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch(event.target.value);
    }
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <Paper className={classes.paper} onSubmit={() => onSearch(value)} component="form">
        <Typography className={classes.label} component="label" variant="caption">
          {label}
        </Typography>
        {type === "phone" ? (
          <PhoneInput
            addInternationalOption={false}
            defaultCountry="US"
            country="US"
            countries={["US"]}
            type={type}
            className={classes.input}
            placeholder="Search"
            value={value}
            onChange={setValue}
            onKeyDown={handleKeyDown}
            autoFocus
            inputComponent={CustomPhoneInput}
          />
        ) : (
          <InputBase
            type={type}
            className={classes.input}
            placeholder="Search"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        )}
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton type="submit" className={classes.iconButton}>
          <SearchIcon />
        </IconButton>
      </Paper>

      {secondaryAction}
    </Box>
  );
}
