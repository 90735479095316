import React from "react";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    marginLeft: 20,
    colorPrimary: "red",
    colorSecondary: "green",
  },
  searchIcon: {
    marginLeft: 10,
  },
}));

const SearchingLabel = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      Submitting
      <CircularProgress size={20} className={classes.circularProgress} />
    </React.Fragment>
  );
};

const DefaultLabel = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      Submit
      <PublishIcon className={classes.searchIcon} size={20} />
    </React.Fragment>
  );
};

const SubmitButton = ({ searching, disabled }) => {
  return (
    <Button size="small" type="submit" disabled={disabled}>
      {searching ? <SearchingLabel /> : <DefaultLabel />}
    </Button>
  );
};

export default SubmitButton;
