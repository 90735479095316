import { Typography, withStyles } from "@material-ui/core";

export const SuccessTypography = withStyles({
  root: {
    color: "green",
    fontWeight: 800,
  },
})(Typography);

export const WarningTypography = withStyles({
  root: {
    color: "red",
    fontWeight: 600,
  },
})(Typography);

export const HeadingTypography = withStyles({
  root: {
    paddingBottom: 25,
  },
})(Typography);
