import React from "react";
import * as R from "ramda";
import { useQuery } from "react-query";
import Container from "@material-ui/core/Container";
import { useParams, useHistory } from "react-router-dom";
import camelcaseKey from "camelcase-keys";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import RefreshIcon from "@material-ui/icons/Refresh";
import DisplayCallDoc from "./DisplayCallDoc";
import useAxios from "../../../hooks/useAxios";
import SearchBox from "../../../lib/SearchBox";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import { Alert, AlertTitle } from "@material-ui/lab";

const CallDocument = () => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const axios = useAxios();
  const { callId } = params;
  const query = useQuery(
    ["callId", callId],
    () => axios.post("get_call_document", { doc_unique_name: callId }),
    { enabled: !!callId },
  );

  const callDoc = camelcaseKey(R.path(["data", "data"], query), { deep: true });
  const callInProgress = !!callDoc && !R.hasPath(["conference", "endTimeEpoch"], callDoc);

  const loadingSkeleton = (
    <Box className={classes.skeleton}>
      <Skeleton variant="rect" height={40} />
      <Skeleton variant="rect" height={48} />
      <Skeleton variant="rect" height={418} />
    </Box>
  );

  return (
    <Container maxWidth="md" className={classes.container}>
      <SearchBox
        label="Call ID"
        value={callId}
        onSearch={(value) => history.push(`/call-document/${value}`)}
        secondaryAction={
          callInProgress ? (
            <Button
              onClick={() => query.refetch()}
              disabled={query.isLoading}
              endIcon={<RefreshIcon />}
            >
              Refetch
            </Button>
          ) : null
        }
      />

      {query.isLoading && loadingSkeleton}

      {query.error && (
        <Alert severity="error" className={classes.error}>
          <AlertTitle>Couldn't find the call document</AlertTitle>
          Couldn't find a call document with ID {callId}
        </Alert>
      )}

      {callDoc && <DisplayCallDoc className={classes.tabs} callDoc={callDoc} />}
    </Container>
  );
};
export default CallDocument;
